import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Layout, Button, Row, Col, Dropdown, Menu } from "antd";
import { RiCloseLine, RiMenuFill } from "react-icons/ri";
import { SearchNormal1 } from "iconsax-react";
import HeaderSearch from "./HeaderSearch";
import HeaderUser from "./HeaderUser";
import HeaderNotifications from "./HeaderNotifications";
// import HeaderCart from "./HeaderCart";
import MenuLogo from "../menu/logo";
import MenuHorizontal from "../menu/item/MenuHorizontal";
import MenuMobile from "../menu/mobile";
import {
  ContainerOutlined,
  DesktopOutlined,
  PieChartOutlined,
} from "@ant-design/icons";
import LanguageTranslation from "./LanguageTranslation";
import { useTranslation } from "react-i18next";
// import HeaderLanguages from "./HeaderLanguages";

const { Header } = Layout;
function getItem(label, key, icon) {
  return {
    key,
    icon,

    label,
  };
}
const menu = (
  <Menu
    items={[
      {
        key: "1",
        label: (
          <a rel="noopener noreferrer" href="/seller-dashboard/homepage">
            Dashboard
          </a>
        ),
        icon: <PieChartOutlined />,
      },
      {
        key: "2",
        label: (
          <a rel="noopener noreferrer" href="/seller-dashboard/product">
            Products
          </a>
        ),
        icon: <DesktopOutlined />,
      },
      {
        key: "3",
        label: (
          <a rel="noopener noreferrer" href="/seller-dashboard/category">
            Category
          </a>
        ),
        icon: <ContainerOutlined />,
      },
      {
        key: "3",
        label: (
          <a rel="noopener noreferrer" href="/seller-dashboard/orders">
            Orders
          </a>
        ),
        icon: <ContainerOutlined />,
      },
    ]}
  />
);
const items = [
  getItem("Dashboard", "/seller-dashboard/homepage", <PieChartOutlined />),
  getItem("Products", "/seller-dashboard/product", <DesktopOutlined />),
  getItem("Category", "/seller-dashboard/category", <ContainerOutlined />),

  getItem("Orders", "/seller-dashboard/orders", <ContainerOutlined />),
];
const HeaderHorizontal = (props) => {
  const { visible, setVisible } = props;
  const { t, i18n } = useTranslation();

  const [searchHeader, setSearchHeader] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const language = localStorage.getItem("lng");
  const customise = useSelector((state) => state.customise);
  // Header Class
  const [headerClass, setHeaderClass] = useState();

  useEffect(() => {
    if (customise.navigationFull) {
      setHeaderClass(" hp-header-full");
    } else if (customise.navigationBg) {
      setHeaderClass(" hp-header-bg");
    } else {
      setHeaderClass("");
    }
  }, [customise]);
  const onClose = () => {
    setVisible(false);
  };
  const inputFocusRef = useRef(null);
  const inputFocusProp = {
    ref: inputFocusRef,
  };

  // Search Active
  setTimeout(() => setSearchActive(searchHeader), 100);

  const searchClick = () => {
    setSearchHeader(true);

    setTimeout(() => {
      inputFocusRef.current.focus({
        cursor: "start",
      });
    }, 200);
  };

  // Mobile Sidebar
  const showDrawer = () => {
    setVisible(true);
    setSearchHeader(false);
  };

  // Children
  const headerChildren = () => {

    return (
      language ? (
        language == 'en' ? (
          <Row
            className="hp-w-100 hp-position-relative"
            align="middle"
            justify="space-between"
          >
            
            <Col span={2}>
              <MenuLogo />

              <Col className="hp-mobile-sidebar-button">
                <Button
                  className="hp-mobile-sidebar-button"
                  type="text"
                  onClick={showDrawer}
                  icon={
                    <RiMenuFill
                      size={24}
                      className="remix-icon hp-text-color-black-80"
                    />
                  }
                />
              </Col>
            </Col>

            {!searchHeader && (
              <Col flex="1 0 0" className="hp-mx-24">
                <MenuHorizontal />
              </Col>
            )}

            <Col
              flex="1"
              style={{ display: !searchHeader ? "none" : "block" }}
              className={`hp-ml-md-0 hp-pr-md-0 hp-ml-32 hp-mr-16 hp-pl-0 hp-header-search ${searchActive && "hp-header-search-active"
                }`}
            >
              <HeaderSearch
                inputFocusProp={inputFocusProp}
                setSearchHeader={setSearchHeader}
              />
            </Col>

            <Col>
              <Row align="middle" gutter={[12, 12]}>
                <Col>
                  <a
                    href="https://www.matjars.com"
                    target={"_blank"}
                    style={{ color: "#db7a00", fontWeight: "bold" }}
                  >
                    {t('Switch_to_buyer')}
                  </a>
                </Col>
                <Col>
                  <LanguageTranslation />
                  {/* <Language */}
                </Col>
                <Col className="hp-mr-sm-0 hp-mr-4 hp-d-flex-center">
                  {!searchHeader ? (
                    <Button
                      ghost
                      className="hp-border-none hp-hover-bg-black-10 hp-hover-bg-dark-100"
                      style={{ margin: "3px" }}
                      icon={
                        <SearchNormal1
                          size={28}
                          set="curved"
                          className="hp-text-color-black-80 hp-text-color-dark-30"
                        />
                      }
                      onClick={() => searchClick()}
                    />
                  ) : (
                    <Button
                      ghost
                      className="hp-border-none hp-hover-bg-black-10 hp-hover-bg-dark-100"
                      icon={
                        <RiCloseLine
                          size={24}
                          className="hp-text-color-black-80 hp-text-color-dark-30"
                        />
                      }
                      onClick={() => setSearchHeader(false)}
                    />
                  )}
                </Col>
                <HeaderUser />
              </Row>
            </Col>
            
          </Row>
        ) : (
          <Row
            className="hp-w-100 hp-position-relative"
            align="middle"
            justify="space-between"
          >

            <Col
              flex="1"
              style={{ display: !searchHeader ? "none" : "block" }}
              className={`hp-ml-md-0 hp-pr-md-0 hp-ml-32 hp-mr-16 hp-pl-0 hp-header-search ${searchActive && "hp-header-search-active"
                }`}
            >
              <HeaderSearch
                inputFocusProp={inputFocusProp}
                setSearchHeader={setSearchHeader}
              />
            </Col>

            <Col>
              <Row align="middle" gutter={[12, 12]}>
                {/* profile section */}
                <HeaderUser />
                {/* search icon */}
                <Col className="hp-mr-sm-0 hp-mr-4 hp-d-flex-center">
                  {!searchHeader ? (
                    <Button
                      ghost
                      className="hp-border-none hp-hover-bg-black-10 hp-hover-bg-dark-100"
                      style={{ margin: "3px" }}
                      icon={
                        <SearchNormal1
                          size={28}
                          set="curved"
                          className="hp-text-color-black-80 hp-text-color-dark-30"
                        />
                      }
                      onClick={() => searchClick()}
                    />
                  ) : (
                    <Button
                      ghost
                      className="hp-border-none hp-hover-bg-black-10 hp-hover-bg-dark-100"
                      icon={
                        <RiCloseLine
                          size={24}
                          className="hp-text-color-black-80 hp-text-color-dark-30"
                        />
                      }
                      onClick={() => setSearchHeader(false)}
                    />
                  )}
                </Col>


                {/* language translation */}
                <Col>
                  <LanguageTranslation />

                </Col>
                {/* Switch to buyer */}
                <Col>
                  <a
                    href="https://www.matjars.com"
                    target={"_blank"}
                    style={{ color: "#db7a00", fontWeight: "bold" }}
                  >
                    {t('Switch_to_buyer')}
                  </a>
                </Col>

              </Row>
            </Col>

            {!searchHeader && (
              <Col flex="1 0 0" className="hp-mx-24">
                <MenuHorizontal />
              </Col>
            )}

            <Col span={3}>
              <MenuLogo />

              <Col className="hp-mobile-sidebar-button">
                <Button
                  className="hp-mobile-sidebar-button"
                  type="text"
                  onClick={showDrawer}
                  icon={
                    <RiMenuFill
                      size={24}
                      className="remix-icon hp-text-color-black-80"
                    />
                  }
                />
              </Col>
            </Col>
          </Row>
        )
      ) : (
        <Row
          className="hp-w-100 hp-position-relative"
          align="middle"
          justify="space-between"
        >
          <Col span={2}>
            <MenuLogo />

            <Col className="hp-mobile-sidebar-button">
              <Button
                className="hp-mobile-sidebar-button"
                type="text"
                onClick={showDrawer}
                icon={
                  <RiMenuFill
                    size={24}
                    className="remix-icon hp-text-color-black-80"
                  />
                }
              />
            </Col>
          </Col>

          {!searchHeader && (
            <Col 
            span={13}
            // flex="1 0 0"
             className="hp-mx-24">
              <MenuHorizontal />
            </Col>
          )}

          <Col
          span={6}
            // flex="1"
            style={{ display: !searchHeader ? "none" : "block" }}
            className={`hp-ml-md-0 hp-pr-md-0 hp-ml-32 hp-mr-16 hp-pl-0 hp-header-search ${searchActive && "hp-header-search-active"
              }`}
          >
            <HeaderSearch
              inputFocusProp={inputFocusProp}
              setSearchHeader={setSearchHeader}
            />
          </Col>

          <Col>
            <Row align="middle" gutter={[12, 12]}>
              <Col>
                <a
                  href="https://www.matjars.com"
                  target={"_blank"}
                  style={{ color: "#db7a00", fontWeight: "bold" }}
                >
                  {t('Switch_to_buyer')}
                </a>
              </Col>
              <Col>
                <LanguageTranslation />
                {/* <Language */}
              </Col>
              <Col className="hp-mr-sm-0 hp-mr-4 hp-d-flex-center">
                {!searchHeader ? (
                  <Button
                    ghost
                    className="hp-border-none hp-hover-bg-black-10 hp-hover-bg-dark-100"
                    style={{ margin: "3px" }}
                    icon={
                      <SearchNormal1
                        size={28}
                        set="curved"
                        className="hp-text-color-black-80 hp-text-color-dark-30"
                      />
                    }
                    onClick={() => searchClick()}
                  />
                ) : (
                  <Button
                    ghost
                    className="hp-border-none hp-hover-bg-black-10 hp-hover-bg-dark-100"
                    icon={
                      <RiCloseLine
                        size={24}
                        className="hp-text-color-black-80 hp-text-color-dark-30"
                      />
                    }
                    onClick={() => setSearchHeader(false)}
                  />
                )}
              </Col>
              <HeaderUser />
            </Row>
          </Col>
        </Row>
      )
      //  language == 'en' ? (
      //     <Row
      //       className="hp-w-100 hp-position-relative"
      //       align="middle"
      //       justify="space-between"
      //     >
      //       <Col span={3}>
      //         <MenuLogo />

      //         <Col className="hp-mobile-sidebar-button">
      //           <Button
      //             className="hp-mobile-sidebar-button"
      //             type="text"
      //             onClick={showDrawer}
      //             icon={
      //               <RiMenuFill
      //                 size={24}
      //                 className="remix-icon hp-text-color-black-80"
      //               />
      //             }
      //           />
      //         </Col>
      //       </Col>

      //       {!searchHeader && (
      //         <Col flex="1 0 0" className="hp-mx-24">
      //           <MenuHorizontal />
      //         </Col>
      //       )}

      //       <Col
      //         flex="1"
      //         style={{ display: !searchHeader ? "none" : "block" }}
      //         className={`hp-ml-md-0 hp-pr-md-0 hp-ml-32 hp-mr-16 hp-pl-0 hp-header-search ${searchActive && "hp-header-search-active"
      //           }`}
      //       >
      //         <HeaderSearch
      //           inputFocusProp={inputFocusProp}
      //           setSearchHeader={setSearchHeader}
      //         />
      //       </Col>

      //       <Col>
      //         <Row align="middle" gutter={[12, 12]}>
      //           <Col>
      //             <a
      //               href="https://www.matjars.com"
      //               target={"_blank"}
      //               style={{ color: "#db7a00", fontWeight: "bold" }}
      //             >
      //               {t('Switch_to_buyer')}
      //             </a>
      //           </Col>
      //           <Col>
      //             <LanguageTranslation />
      //             {/* <Language */}
      //           </Col>
      //           <Col className="hp-mr-sm-0 hp-mr-4 hp-d-flex-center">
      //             {!searchHeader ? (
      //               <Button
      //                 ghost
      //                 className="hp-border-none hp-hover-bg-black-10 hp-hover-bg-dark-100"
      //                 style={{ margin: "3px" }}
      //                 icon={
      //                   <SearchNormal1
      //                     size={28}
      //                     set="curved"
      //                     className="hp-text-color-black-80 hp-text-color-dark-30"
      //                   />
      //                 }
      //                 onClick={() => searchClick()}
      //               />
      //             ) : (
      //               <Button
      //                 ghost
      //                 className="hp-border-none hp-hover-bg-black-10 hp-hover-bg-dark-100"
      //                 icon={
      //                   <RiCloseLine
      //                     size={24}
      //                     className="hp-text-color-black-80 hp-text-color-dark-30"
      //                   />
      //                 }
      //                 onClick={() => setSearchHeader(false)}
      //               />
      //             )}
      //           </Col>
      //           <HeaderUser />
      //         </Row>
      //       </Col>
      //     </Row>
      //     ) : (
      //       <Row
      //       className="hp-w-100 hp-position-relative"
      //       align="middle"
      //       justify="space-between"
      //     >

      //   <Col
      //         flex="1"
      //         style={{ display: !searchHeader ? "none" : "block" }}
      //         className={`hp-ml-md-0 hp-pr-md-0 hp-ml-32 hp-mr-16 hp-pl-0 hp-header-search ${searchActive && "hp-header-search-active"
      //           }`}
      //       >
      //         <HeaderSearch
      //           inputFocusProp={inputFocusProp}
      //           setSearchHeader={setSearchHeader}
      //         />
      //       </Col>

      //       <Col>
      //         <Row align="middle" gutter={[12, 12]}>
      //             {/* profile section */}
      //         <HeaderUser />
      //          {/* search icon */}
      //          <Col className="hp-mr-sm-0 hp-mr-4 hp-d-flex-center">
      //             {!searchHeader ? (
      //               <Button
      //                 ghost
      //                 className="hp-border-none hp-hover-bg-black-10 hp-hover-bg-dark-100"
      //                 style={{ margin: "3px" }}
      //                 icon={
      //                   <SearchNormal1
      //                     size={28}
      //                     set="curved"
      //                     className="hp-text-color-black-80 hp-text-color-dark-30"
      //                   />
      //                 }
      //                 onClick={() => searchClick()}
      //               />
      //             ) : (
      //               <Button
      //                 ghost
      //                 className="hp-border-none hp-hover-bg-black-10 hp-hover-bg-dark-100"
      //                 icon={
      //                   <RiCloseLine
      //                     size={24}
      //                     className="hp-text-color-black-80 hp-text-color-dark-30"
      //                   />
      //                 }
      //                 onClick={() => setSearchHeader(false)}
      //               />
      //             )}
      //           </Col>


      //           {/* language translation */}
      //           <Col>
      //             <LanguageTranslation />

      //           </Col>
      //           {/* Switch to buyer */}
      //           <Col>
      //             <a
      //               href="https://www.matjars.com"
      //               target={"_blank"}
      //               style={{ color: "#db7a00", fontWeight: "bold" }}
      //             >
      //                {t('Switch_to_buyer')}
      //             </a>
      //           </Col>

      //         </Row>
      //       </Col>

      //       {!searchHeader && (
      //         <Col flex="1 0 0" className="hp-mx-24">
      //           <MenuHorizontal />
      //         </Col>
      //       )}

      //       <Col span={3}>
      //         <MenuLogo />

      //         <Col className="hp-mobile-sidebar-button">
      //           <Button
      //             className="hp-mobile-sidebar-button"
      //             type="text"
      //             onClick={showDrawer}
      //             icon={
      //               <RiMenuFill
      //                 size={24}
      //                 className="remix-icon hp-text-color-black-80"
      //               />
      //             }
      //           />
      //         </Col>
      //       </Col>
      //     </Row>
      //     )

    );
  };

  return (
    <Header className={"hp-header-horizontal" + headerClass}>
      <Row justify="center" className="hp-w-100">
        <Col span={24}>{headerChildren()}</Col>
      </Row>

      <MenuMobile onClose={onClose} visible={visible} />
    </Header>
  );
}
export default HeaderHorizontal