import React from 'react'
import { Link, useHistory } from 'react-router-dom';
import { getAllProduct, getCategoryMenu } from '../../../service/api';
import { Input, TreeSelect } from "antd";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { productsFilter, categoryFilter } from '../../../redux/product/productActions';
import './HeaderSecton.css'
const { Search } = Input;
const HeaderSearch = (props) => {
    let [productsList, setProductsList] = useState([]);
    let [categoryList, setCategoryList] = useState([]);
    let [selectCategory, setSelectCategory] = useState();
    let [searchProduct, setSearchProduct] = useState();
    let [filterProduct, setFilterProduct] = useState();
    let [showFilterData, setShowFilterData] = useState(false)
    const [page, setPage] = useState(1)
    let [subCategoryData, setSubCategoryData] = useState([])
    const [value, setValue] = useState();
    // ===============Tree Select ========================
    const dispatch = useDispatch();
    const history = useHistory()
    const productFilter = useSelector((state) => state.products.productFilter);
    const categoryFilterr = useSelector((state) => state.products.categoryFilter);
    const getCategory = async () => {
        try {
            let category = await getCategoryMenu();
            const categoryData = category.data.map((item) => item)
            setCategoryList(categoryData)
        } catch (err) {
            console.log(err, "err");
        }
    };
    const onSearch = (value) => history.push(`/searchproduct/${selectCategory}?${searchProduct}`)
    const filterCategory = (category) => {
        // from api like {category name, category od, category children}
        //  then save to state([]) ====================
        // --------------------------------------------------
        // const filteredApi = category.map((cat) => {
        //     if (cat.subCategory.length > 0) {
        //         return ({
        //             ['title']: cat.name,
        //             ['value']: cat._id,
        //             ['children']:
        //                 cat?.subCategory.map((child) => {
        //                     return ({
        //                         ['title']: child.name,
        //                         ['value']: child._id
        //                     })
        //                 })
        //         })
        //     } else {
        //         return ({
        //             ['title']: cat.name,
        //             ['value']: cat._id,
        //         })
        //     }
        // })
        const filteredApi = category?.map((cat) => {
            // if (cat.subCategory.length > 0) {
            //     return ({

            //         ['key']: cat._id,
            //         ['type']: 'group',
            //         ['label']: cat.name,
            //         ['children']:
            //             cat?.subCategory.map((child) => {
            //                 return ({
            //                     ['label']: child.name,
            //                     ['key']: child._id
            //                 })
            //             })
            //     })
            // } 
            // else {
            return ({
                ['label']: cat.name,
                ['key']: cat._id,
            })
            // }
        })
        setSubCategoryData(filteredApi)
    }
    React.useMemo(() => {
        filterCategory(categoryList)

    }, [categoryList])
    const getProduct = async () => {
        try {
            let product = await getAllProduct({ page, limit: 50 });
            const productDetail = product.data.list.products
            setProductsList(productDetail)
        } catch (err) {
            // console.log(err, "err");
        }
    };
    useEffect(() => {
   getProduct();
        getCategory()
        if (!selectCategory && searchProduct) {
            dispatch(categoryFilter('All Category'));
        }
        // if(!searchProduct){
        //     dispatch(productsFilter(productsList))
        // }
    }, [searchProduct, selectCategory])
    const changeSearch = async (e) => {
        setSearchProduct(e.target.value);
        filterFunction(e.target.value)
        dispatch(productsFilter(e.target.value));
    }
    // dispatch(productsFilter(searchProduct));
    const filterFunction = (text) => {
        const filter = productsList.filter((item) => (
            item.title.toLowerCase().includes(text.toLowerCase())
        ))
        setFilterProduct(filter)
        setShowFilterData(true)
    }
    // if(filterProduct){
    //     setShowFilterData(true)
    // }
    const handleChangeCategory = (value, i) => {
        setSelectCategory(i[0])
        dispatch(categoryFilter(i[0]));
        setValue(i)

        history.push(`/searchproduct/`)
        // history.push(`/searchproduct/${selectCategory}?${searchProduct}`)
    }
    function linkHandleClick() {
        props.setSearchHeader(false)
    }
    const removeListData = () => {
        setTimeout(() => {
            !setShowFilterData(false)
        }, 500)
    }
    const removeOnClickListData = () => {
        !setShowFilterData(false)
    }
  
    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ marginRight: '10px' }}>
                    <TreeSelect
                        style={{
                            width: '100%',
                        }}
                        showSearch
                        value={value}
                        dropdownStyle={{
                            maxHeight: 400,
                            overflow: 'auto',
                        }}
                        dropdownMatchSelectWidth={false}
                        allowClear
                        treeData={subCategoryData}
                        placeholder="Please select"
                        treeDefaultExpandAll
                        onChange={handleChangeCategory}
                    />

                </div>
                <div style={{ width: '100%', marginTop: '25px' }}>
                    <Search
                        {...props.inputFocusProp}
                        placeholder="input search text"
                        allowClear
                        enterButton="Search"
                        size="middle"
                        value={productFilter}
                        onChange={changeSearch}
                        onSearch={onSearch} />
                </div>
                <div className="hp-header-search-result" style={{ top: "75%", left: '12%' }} onMouseLeave={removeListData}>

                    {/* {filterProduct && ( */}
                    {showFilterData && (
                        filterProduct.map((item, index) => {
                            return (
                                <ul key={index} onClick={removeOnClickListData}>
                                    <li
                                        key={index}
                                        className="hp-font-weight-500"
                                        onClick={() => dispatch(productsFilter(item.title.toLowerCase()))}>
                                        <Link
                                            to={`/searchproduct/`}>{item.title} </Link>
                                        {/* // to={`/searchproduct/${selectCategory}?${searchProduct}`}>{item.title} </Link> */}
                                    </li>
                                </ul>
                            )
                        })
                    )}
                </div>

            </div>

        </>
    )
}

export default HeaderSearch