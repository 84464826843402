import React from "react";

import { Card, Row, Col, Table, Button, Tag } from "antd";
import { Download } from "react-iconly";
import { getOrderSeller } from "../../../../../service/api";
import { useEffect } from "react";
import { useState } from "react";
import { toTitleCase } from "../../../../../configs/helper";
import moment from "moment";
import useApp from "../../../../../useApp";
import IconNotification from "../../../../components/notification/Icon-notification";
import { BASE_URL } from "../../../../../configs/apiConfig";

export default function LatestOrders() {
  const [orderData, setOrderData] = useState([]);
  const { sessionExpired } = useApp();
  // const token = JSON.parse(localStorage.getItem("token"));
  const token = localStorage.getItem("token");
 const getData = async () => {
    try {
      // const orders = await getOrderSeller(token);
     
      const orders = await getOrderSeller(5, undefined, undefined, undefined);
      // const orders = await getOrderSeller();
      setOrderData(orders.data);
    } catch (err) {
      if (err?.response?.status == 401) {
        // sessionExpired();
      } else {
        IconNotification({
          type: "error",
          message: "Fail",
          description: err?.response?.data?.message,
        });
      }
    }
  };

  useEffect(() => {
   getData();
  }, []);

  const columns = [
    {
      title: "Order Number",
      dataIndex: "orderId",
      key: "orderId",
      render: (text, record) => {
        return (
          <>
            <a
              href={
                "orders/" +
                record._id +
                "/" +
                "product" +
                "/" +
                record?.productId
              }
            >
              {record?.orderId}
              {/* {'orders/'+record._id+'/'+'product'+'/'+record.productId} */}
            </a>
          </>
        );
      },
    },
    {
      title: "Product Title",
      dataIndex: "products.title",
      key: "_id",
      sorter: (a, b) => a?.products?.title - b?.products?.title,
      render: (text, record) => {
        return <>{record?.products?.title}</>;
      },
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (text, render) => {
        return (
          <> {moment(render?.created_at).format("YYYY-MM-DD - HH:mm A")} </>
        );
      },
    },
    {
      title: "Order Status",
      dataIndex: "status",
      filters: [
        {
          text: "Cancelled",
          value: "Cancelled",
        },
        {
          text: "Processing",
          value: "Processing",
        },
        {
          text: "Completed",
          value: "Completed",
        },
      ],
      render: (stock) => (
        <>
          {[stock].map((tag) => {
            let color = tag;
            if (tag === "cancelled") {
              color = "error";
            } else if (tag === "processing") {
              color = "warning";
            } else if (tag === "completed") {
              color = "success";
            } else if (tag === "pendingPayment") {
              tag = "Pending Payment";
              color = "warning";
            }
            return (
              <Tag color={color} key={tag}>
                {toTitleCase(tag || "")}
              </Tag>
            );
          })}
        </>
      ),
      // specify the condition of filtering result
      // here is that finding the name started with 'value'
      onFilter: (value, record) => {
        return record.status.toLowerCase() == value.toLowerCase();
      },
      // sorter: (a, b) => a.status.length - b.status.length,
    },
  ];

  return (
    <Card
      style={{ minHeight: "415px" }}
      className="hp-border-color-black-40 hp-project-ecommerce-table-card"
    >
      <Row>
        <Col span={24}>
          <Row align="middle" justify="space-between" className="hp-mb-8">
            <h4 className="hp-mb-0">Latest Orders</h4>

            <Button
              type="link"
              className="hp-p-0 hp-text-color-black-80 hp-hover-text-color-black-100"
              style={{ minHeight: "auto", lineHeight: "normal" }}
              href="/orders"
            >
              See all Orders
            </Button>
          </Row>

          <Table
            columns={columns}
            dataSource={orderData?.list}
            pagination={false}
            scroll={{ x: 700 }}
          />
        </Col>
      </Row>
    </Card>
  );
}
