import * as actionTypes from "./footerTypes";

const INITIAL_STATE = {
  footerNavigation: [],
};

const footerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_NAVIGATION:
      return {
        ...state,
        footerNavigation: action.payload,
      };
    default:
      return state;
  }
};

export default footerReducer;
