
import { Card, Col, Row, Typography } from 'antd'
import React, { useState } from 'react'
const { Text  } = Typography;
import ReactApexChart from 'react-apexcharts'

const TopSellingCategory = () => {
    const [topSellingCategory, setTopSellingCategory] = useState({
           series: [44, 55, 41, 17, 15],
            options: {
              chart: {
                type: 'donut',
                // background: "#f5f5f5",
              },
              stroke: {
                width: 0
              },
              colors: ['hsla(34, 96%, 44%, 1)','hsla(34, 76%, 55%, 1)','hsla(34, 76%, 66%, 1)','hsla(34, 76%, 77%, 1)','hsla(34, 76%, 89%, 1)'],
              labels: ['Car care', 'Interior Accessories', 'Performance parts', 'Oils and fluids', 'Home Decoration'],
         
              dataLabels: {
                enabled: false
            },
            plotOptions: {
              pie: {
                // customScale: 1.6
                // size: 500
                donut: {
                  size: '49%'
                }
              }
            },
            // title: {
            //   text: "Top Selling Category"
            // },
              // responsive: [{
              //   breakpoint: 300,
              //   options: {
              //     chart: {
              //       width: 300
              //     },
              //     legend: {
              //       position: 'bottom'
              //     }
              //   }
              // }]
              responsive: [
                {
                  breakpoint: 1600,
                  options: {
                    chart: {
                      width: 420,
                      height:200,
                      offsetX: -35,
                      offsetY: 10
                    },
                    legend: {
                      position: 'right',
                      floating: false,
                      backgroundColor:'black',
                      fontSize: '14px',
                      fontFamily: 'Helvetica, Arial',
                      fontWeight: 200,
                      
                      offsetX: 9,
                      offsetY: -10
                      // right: '25px'
                    },
                },
              },
                {
                  breakpoint: 1356,
                  options: {
                    chart: {
                      width: 394,
                      height:360,
                      // offsetX: 25,
                      offsetY: 10
                    },
                    legend: {
                      position: 'right',
                      floating: false,
                      backgroundColor:'black',
                      fontSize: '14px',
                      fontFamily: 'Helvetica, Arial',
                      fontWeight: 200,
                      
                      offsetX: 9,
                      offsetY: -15
                      // right: '25px'
                    },
                },
              },
              {
                breakpoint: 1265,
                options: {
                  chart: {
                    width: 390,
                    height:330,
                    // offsetX: -47,
                    // offsetY: -20
                    offsetX: -44,
                    offsetY: -1
                  },
                  legend: {
                    position: 'right',
                    floating: false,
                    backgroundColor:'black',
                    fontSize: '12px',
                    fontFamily: 'Helvetica, Arial',
                    fontWeight: 200,
                    offsetX: 15,
                    offsetY: -5,
                    // width: 160,
                    // height: 200
                    // offsetX: 12,
                    // offsetY: -5
                    // right: '25px'
                  },
              },
            },
            // ----- 1200px
            {
              breakpoint: 1200,
              options: {
                chart: {
                  width: 395,
                  height:350,
                  // offsetX: -47,
                  // offsetY: -20
                  offsetX: -64,
                  offsetY: 10
                },
                legend: {
                  position: 'bottom',
                  floating: false,
                  backgroundColor:'black',
                  fontSize: '14px',
                  fontFamily: 'Helvetica, Arial',
                  fontWeight: 200,
                  offsetX: 22,
                  // offsetX: 15,
                  // offsetY: -5,
                  // width: 160,
                  // height: 200
                  // offsetX: 12,
                  // offsetY: -5
                  // right: '25px'
                },
            },
          },
          {
            breakpoint: 1150,
            options: {
              chart: {
                width: 395,
                height:350,
                // offsetX: -47,
                // offsetY: -20
                offsetX: -64,
                offsetY: 10
              },
              legend: {
                position: 'bottom',
                floating: false,
                backgroundColor:'black',
                fontSize: '14px',
                fontFamily: 'Helvetica, Arial',
                fontWeight: 200,
                offsetX: -10,
                // offsetX: 15,
                // offsetY: -5,
                // width: 160,
                // height: 200
                // offsetX: 12,
                // offsetY: -5
                // right: '25px'
              },
          },
        },
        {
          breakpoint: 1080,
          options: {
            chart: {
              width: 395,
              height:350,
              // offsetX: -47,
              // offsetY: -20
              offsetX: -64,
              offsetY: 10
            },
            legend: {
              position: 'bottom',
              floating: false,
              backgroundColor:'black',
              fontSize: '14px',
              fontFamily: 'Helvetica, Arial',
              fontWeight: 200,
              offsetX: -10,
              // offsetX: 15,
              // offsetY: -5,
              // width: 160,
              // height: 200
              // offsetX: 12,
              // offsetY: -5
              // right: '25px'
            },
        },
      },
          // ---- 1075 px
          {
            breakpoint: 1075,
            options: {
              chart: {
                width: 335,
                height:330,
                // offsetX: -47,
                // offsetY: -20
                offsetX: -64,
                offsetY: -1
              },
              legend: {
                position: 'bottom',
                floating: false,
                backgroundColor:'black',
                fontSize: '12px',
                fontFamily: 'Helvetica, Arial',
                fontWeight: 200,
                offsetX: 15,
                offsetY: -5,
                // width: 160,
                // height: 200
                // offsetX: 12,
                // offsetY: -5
                // right: '25px'
              },
          },
        },
        // ---- 995 px
        {
          breakpoint: 992,
          // breakpoint: 776,
          options: {
            chart: {
              width: 414,
              height: 280,
              // color:'red',
              
              // offsetX: -47,
              // offsetY: -20
              offsetX: -32,
              offsetY: 12
            },
            legend: {
              position: 'right',
              floating: false,
              backgroundColor: 'black',
              fontSize: '11px',
              fontFamily: 'Helvetica, Arial',
              fontWeight: 200,
              // width: 120,
              offsetX: 15,
              offsetY: -5
              // right: '25px'
            },
        },
      },
      // ---- 835px
           {
                breakpoint: 835,
                // breakpoint: 776,
                options: {
                  chart: {
                    width: 364,
                    height: 250,
                    // color:'red',
                    
                    // offsetX: -47,
                    // offsetY: -20
                    offsetX: -32,
                    // offsetY: -1
                  },
                  legend: {
                    position: 'right',
                    floating: false,
                    backgroundColor: 'black',
                    fontSize: '11px',
                    fontFamily: 'Helvetica, Arial',
                    fontWeight: 200,
                    // width: 120,
                    offsetX: 15,
                    offsetY: -5
                    // right: '25px'
                  },
              },
            },
            {
              breakpoint: 790,
              options: {
                chart: {
                  width: 354,
                  height: 290,
                  // width: 200,
                  // height: 100,
                  // offsetX: -47,
                  // offsetY: -20
                  // offsetX: -69,
                  // offsetY: -1
                },
                legend: {
                  position: 'bottom',
                  floating: false,
                  backgroundColor: 'black',
                  // fontSize: '42px',
                  fontFamily: 'Helvetica, Arial',
                  fontWeight: 200,
                  // width: 120,
                  offsetX: -15,
                  offsetY: -5
                  // right: '25px'
                },
            },
          },
          //   {
          //     breakpoint: 768,
          //     // breakpoint: 776,
          //     options: {
          //       chart: {
          //         width: 514,
          //         height: 500,
          //         // color:'red',
                  
          //         // offsetX: -47,
          //         // offsetY: -20
          //         offsetX: 52,
          //         // offsetY: -1
          //       },
          //       legend: {
          //         position: 'right',
          //         floating: false,
          //         backgroundColor: 'black',
          //         fontSize: '14px',
          //         fontFamily: 'Helvetica, Arial',
          //         fontWeight: 200,
          //         // width: '60%',
          //         offsetX: 15,
          //         offsetY: -5
          //         // right: '25px'
          //       },
          //   },
          // },
          //   {
          //     breakpoint: 656,
          //     // breakpoint: 776,
          //     options: {
          //       chart: {
          //         width: 414,
          //         height: 410,
          //         // color:'red',
                  
          //         // offsetX: -47,
          //         // offsetY: -20
          //         offsetX: -32,
          //         // offsetY: -1
          //       },
          //       legend: {
          //         position: 'bottom',
          //         floating: false,
          //         backgroundColor: 'black',
          //         fontSize: '11px',
          //         fontFamily: 'Helvetica, Arial',
          //         fontWeight: 200,
          //         // width: '60%',
          //         offsetX: 15,
          //         offsetY: -5
          //         // right: '25px'
          //       },
          //   },
          // },
          {
            breakpoint: 768,
            // breakpoint: 776,
            options: {
              chart: {
                width: 524,
                height: 490,
                // color:'red',
                
                // offsetX: -47,
                // offsetY: -20
                offsetX: 32,
                // offsetY: -1
              },
              legend: {
                position: 'right',
                floating: false,
                backgroundColor: 'black',
                fontSize: '14px',
                fontFamily: 'Helvetica, Arial',
                fontWeight: 200,
                // width: '60%',
                offsetX: 15,
                offsetY: -5
                // right: '25px'
              },
          },
          },
          {
          breakpoint: 656,
          // breakpoint: 776,
          options: {
            chart: {
              width: 460,
              height: 440,
              offsetX: 32,
            },
            legend: {
              position: 'right',
              floating: false,
              backgroundColor: 'black',
              fontSize: '11px',
              fontFamily: 'Helvetica, Arial',
              fontWeight: 200,
              offsetY: -5
             },
          },
          },
          {
            breakpoint: 594,
            // breakpoint: 776,
            options: {
              chart: {
                width: 460,
              height: 440,
              offsetX: 2,
              },
              legend: {
                position: 'right',
                floating: false,
                backgroundColor: 'black',
                fontSize: '11px',
                fontFamily: 'Helvetica, Arial',
                fontWeight: 200,
                offsetY: -5
               },
            },
            },
          {
            breakpoint: 552,
            // breakpoint: 776,
            options: {
              chart: {
                // width: 420,
                // height: 350,
                width: 460,
              height: 440,
               
                // color:'red',
                
                // offsetX: -47,
                // offsetY: -20
                offsetX: -32,
                // offsetY: -1
              },
              legend: {
                position: 'right',
                floating: false,
                backgroundColor: 'black',
                fontSize: '11px',
                fontFamily: 'Helvetica, Arial',
                fontWeight: 200,
                // width: '60%',
                // offsetX: 45,
                offsetY: -5
                // right: '25px'
              },
            },
            },
            {
              breakpoint: 514,
              // breakpoint: 776,
              options: {
                chart: {
                  // width: 420,
                  // height: 350,
                  width: 400,
                height: 370,
                 
                  // color:'red',
                  
                  // offsetX: -47,
                  // offsetY: -20
                  offsetX: -32,
                  // offsetY: -1
                },
                legend: {
                  position: 'bottom',
                  floating: false,
                  backgroundColor: 'black',
                  fontSize: '11px',
                  fontFamily: 'Helvetica, Arial',
                  fontWeight: 200,
                  // width: '60%',
                  // offsetX: 45,
                  offsetY: -5
                  // right: '25px'
                },
              },
              },
            {
              breakpoint: 484,
              // breakpoint: 776,
              options: {
                chart: {
                  width: 420,
                  height: 350,
                  // width: 620,
                  // height: 540,
                  
                  // color:'red',
                  
                  // offsetX: -47,
                  // offsetY: -20
                  offsetX: -62,
                  // offsetY: -1
                },
                legend: {
                  position: 'bottom',
                  floating: false,
                  backgroundColor: 'black',
                  fontSize: '11px',
                  fontFamily: 'Helvetica, Arial',
                  fontWeight: 200,
                  // width: '60%',
                  offsetX: 15,
                  // offsetY: -10
                  // right: '25px'
                },
              },
              },
            ]
            }
            })
  return (
    <Card bodyStyle={{paddingBottom:'34px'}}
    >
      <Text style={{fontWeight:'900', color:'hsla(221, 43%, 11%, 1)'}}>Top Selling Category</Text>
     
    <ReactApexChart options={topSellingCategory.options} series={topSellingCategory.series} type="donut"
    //  width={400}
      />
      </Card>
  )
}

export default TopSellingCategory