import * as actionTypes from './buyerTypes';
export const buyerRegister = (value) => {
    return {
        type: actionTypes.REGISTER,
        payload: value
    }
}
export const buyerLogin = (value) => {
    return {
        type: actionTypes.LOGIN,
        payload: value
    }
}