import React, { useState } from 'react';
import VirtualList from 'rc-virtual-list';
import { Avatar, List, Progress, Row, Col, Typography } from 'antd';

const ContainerHeight = 200;

const TopSellingRegion = () => {
    const [topSellingRegion, setTopSellingRegion] = useState([

        {
          id: 1,
          product: "Dubai",
          percent: (115 / 500) * 100,
          color: 'hsla(33, 96%, 44%, 1)'
        },
    
        {
          id: 2,
          product: 'Abu Dhabi',
          percent: (250 / 500) * 100,
          color: 'hsla(33, 96%, 44%, 1)'
        },
        {
          id: 3,
          product: 'Sharjah',
          percent: (30 / 500) * 100,
          color: 'hsla(33, 96%, 44%, 1)'
        },
        {
          id: 4,
          product: "Ras Al Khaimah",
          percent: (85 / 500) * 100,
          color: 'hsla(33, 96%, 44%, 1)'
        },
        {
          id: 5,
          product: "Ajman",
          percent: (305 / 500) * 100,
          color: 'hsla(33, 96%, 44%, 1)'
        },
        {
          id: 6,
          product: "Fujairah",
          percent: (235 / 500) * 100,
          color: 'hsla(33, 96%, 44%, 1)'
        },
        {
          id: 7,
          product: "Umm Al Quwain",
          percent: (185 / 500) * 100,
          color: 'hsla(33, 96%, 44%, 1)'
        },
    
      ])

  return (
    <List>
      <VirtualList
        data={topSellingRegion}
        height={ContainerHeight}
        itemHeight={80} // Adjust the item height based on your content
        itemKey="id"
      >
        {(topSellingProduct) => (
          <List.Item key={topSellingProduct.id} style={{padding:'0px', borderBottom:'0px'}}>
            <div style={{ width: '100%' }}>
              <Row style={{ marginTop: '6%' }}>
                <Col span={24}>
                  <Typography.Text>{topSellingProduct.product}</Typography.Text>
                </Col>
                <Col xs={24} md={20}>
                  <Progress
                    strokeColor={topSellingProduct.color}
                    percent={topSellingProduct.percent}
                    showInfo={true}
                    strokeWidth={10}
                  />
                </Col>
              </Row>
            </div>
          </List.Item>
        )}
      </VirtualList>
    </List>
  );
};

export default TopSellingRegion;
