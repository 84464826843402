import React from "react";

import { Card, Row, Col } from "antd";
import { Discount } from "react-iconly";
import { Link } from "react-router-dom";
export default function Profit() {
  return (
    <Card className="hp-border-color-black-40" bodyStyle={{padding:'12px 12px 12px 24px'}}>
    <p style={{textAlign:'end', margin:'0'}}>
    <Link to='/orders'>
          see profit
           </Link>
        </p>
      <Row>
        <Col className="hp-mt-4">
          <h3 className="hp-mb-4">
            945.25
            <span className="hp-badge-text hp-ml-8 hp-text-color-danger-1">
              +3%
            </span>
          </h3>

          <p className="hp-p1-body hp-mb-0 hp-text-color-black-80 hp-text-color-dark-30">
            Gross Profit
          </p>
        </Col>
      </Row>
    </Card>
  );
}