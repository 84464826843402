import * as actionTypes from "./authTypes";

const INITIAL_STATE = {
  isAuthorized: false,
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.IS_AUTHORIZED:
      return {
        ...state,
        isAuthorized: true,
      };
    default:
      return state;
  }
};

export default authReducer;
