import * as actionTypes from "./productTypes";
export const addProducts = (value) => {
  return {
    type: actionTypes.ADD_PRODUCTS,
    payload: value,
  };
};
export const displayProducts = (value)=> {
  return {
    type: actionTypes.PRODUCTLIST,
    payload: value,
  }
}
export const editProducts = (value) => {
  return {
    type: actionTypes.EDIT_PRODUCT,
    payload: value,
  };
};

export const addSearch = (value) => {
  return {
    type: actionTypes.ADD_SEARCH,
    payload: value,
  };
};

export const addCategory = (value) => {
  return {
    type: actionTypes.ADD_CATEGORY,
    payload: value,
  };
};

export const addBrand = (value) => {
  return {
    type: actionTypes.ADD_BRAND,
    payload: value,
  };
};

export const useProduct = (value) => {
  return {
    type: actionTypes.USE_PRODUCT,
    payload: value,
  };
};
// export const showProduct = (value) => {
//   return {
//     type: actionTypes.SHOW_PRODUCT,
//     payload: value,
//   };
// };
export const categoryFilter = (value)=>{
return {
  type: actionTypes.CATEGORY_FILTER,
  payload: value,
};
};
export const productsFilter = (value) => {
  return {
    type: actionTypes.FILTER_PRODUCT,
    payload: value,
  };
};
export const productsTableFilter = (value) => {
 return {
    type: actionTypes.FILTER_TABLE_PRODUCT,
    payload: value,
  };
};
export const dateFilterr = (value)=> {
  return {
    type: actionTypes.DATE_FILTER,
    payload: value
  }
}
export const incrementCounter = () => {
 return {
    type: actionTypes.PRODUCT_INCREMENT,
    // payload: value,
  };
  
};

export const decrementCounter = () => {
   return {
    type: actionTypes.PRODUCT_DECREMENT,
    // payload: value,
  };
};

export const resetProductCounter = () => {

  return {
    type: actionTypes.RESET_PRODUCT_COUNTER,
    // payload: value,
  };
};

export const addForm = (value) => {
  return {
    type: actionTypes.PRODUCT_FORM,
    payload: value,
  };
};

export const isProductCreate = (value) => {
  return {
    type: actionTypes.IS_PRODUCT_CREATE,
    payload: value,
  };
};

export const addAttribute = (value) => {
  return {
    type: actionTypes.ADD_ATTRIBUTE,
    payload: value,
  };
};

export const openTicketModal = () => {
  return {
    type: actionTypes.OPEN_TICKET_MODAL,
  };
};

export const closeTicketModal = () => {
  return {
    type: actionTypes.CLOSE_TICKET_MODAL,
  };
};

export const updateTicket = (value) => {
  return {
    type: actionTypes.UPDATE_TICKET,
    payload: value,
  };
};
