import { Card, Col, Progress, Row, Typography } from 'antd'
import React, { useState } from 'react'
const { Title, Text  } = Typography;
import './TopSellingProduct.css'
const TopSellingProduct = () => {
    const [topSellingProduct, setTopSellingProduct] = useState([
        {
            id: 1,
            product: 'Cleaning Gel',
            value: 4694,
            percent: (4694/4694)*100,
            color:'hsla(33, 96%, 44%, 1)'
        },
        {
            id: 2,
            product: "Noco Boost",
            value: 3653,
            percent: (3653/4694)*100,
            color:'hsla(34, 76%, 55%, 1)'
        },
        {
            id: 3,
            product: "Nexpow",
            value: 3164,
            percent: (3164/4694)*100,
            color:'hsla(34, 76%, 66%, 1)'
        },
        {
            id: 4,
            product: 'Battery tender',
            value: 2672,
            percent: (2672/4694)*100,
            color:'hsla(34, 76%, 77%, 1)'
        },
        {
            id: 5,
            product: 'STA Storage',
            value: 2307,
            percent: (2307/4694)*100,
            color: 'hsla(34, 76%, 89%, 1)'
        }
    ])
  return (
  <Card
   bodyStyle={{paddingBottom:'38px'}}
   >
    <Row  style={{}}>
        <Col span={24} style={{margin:'0px',padding:'0px'}}>
             
      <Text style={{fontWeight:'900', color:'hsla(221, 43%, 11%, 1)', marginBottom:'20%'}}>Top Selling Product</Text>
  
        </Col>
        <Col span={24} style={{margin:'22px 0px 0px 0px ',padding:'0px', }}>
      {topSellingProduct.map((topSellingProduct)=> {
        console.log("%c percent value","background: purple; color: white", topSellingProduct.percent)
        return (
            // <>
              <Row style={{
                marginTop:'1px',
              }}>
                    
                    <Col style={{  padding:'0px'}} span={6}>
                    <Text style={{ margin:'0px', width:'100%'}} >{topSellingProduct.product}  </Text>
                 </Col>
                    <Col span={15} style={{padding:'0px'  }}>
                    <Progress strokeColor={topSellingProduct.color} percent={topSellingProduct.percent} showInfo={false} strokeWidth={16} />
               </Col>
               <Col style={{textAlign:'right'}} span={3}>
               <p>{topSellingProduct.value}</p>
               </Col>
                </Row>
             
            // </>
        )
    })}
       </Col>
    </Row>
       {/* <Progress percent={30} /> */}
    {/* <Progress percent={50} status="active" />
    <Progress percent={70} status="exception" />
    <Progress percent={100} />
    <Progress percent={50} showInfo={false} /> */}
    </Card>
  )
}

export default TopSellingProduct
