import React from "react";
import { Card, Table, Tag, Typography } from "antd";
const { Title } = Typography;
const columns = [
  {
    title: "Product Title",
    dataIndex: "title",
    key: "title",
    render: (text) => <a href="#">{text}</a>, // You can link to the product details page
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    key: "quantity",
  },
  {
    title: "Status",
    key: "status",
    dataIndex: "status",
    render: (status) => (
      <span>
        {status === "in-stock" ? (
          <Tag color="green">In Stock</Tag>
        ) : (
          <Tag color="red">Out of Stock</Tag>
        )}
      </span>
    ),
  },
];

const data = [
  {
    key: "1",
    title: "Product 1",
    description: "Description for Product 1",
    price: "$19.99",
    status: "in-stock",
    quantity: 500,
  },
  {
    key: "2",
    title: "Product 2",
    description: "Description for Product 2",
    price: "$24.99",
    status: "out-of-stock",
    quantity: 20
  },
  // Add more product data here
];

const StockTable = () => {
  return (
    <Card>
         <Title level={4}>Product Stock Table</Title>
    <Table columns={columns} dataSource={data} pagination={{ pageSize: 10 }} />
    </Card>
  );
};

export default StockTable;
