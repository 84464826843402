import React, { useEffect, useState } from "react";
import { Row, Col, Select, Button } from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllSeller,
  getDashboardCount,
  getSellerById,
  getTickets,
} from "../../../../service/api";
import { addOrderDashboard } from "../../../../redux/order/orderActions";
import UnreadMsgs from "./widgets/unreadMsgs";
import AwaitingDispatch from "./widgets/awaitingDispatch";
import AwaitingOrders from "./widgets/awaitingOrders";
import Sales from "./widgets/sales";
import Profit from "./widgets/profit";
import Reviews from "./widgets/reviews";
import SalesCard from "./widgets/salesCard";
import ListingCard from "./widgets/listingCard";
import Announcements from "./widgets/announcementsCard";
import IconNotification from "../../../components/notification/Icon-notification";
import AllOrders from "./widgets/allOrders";
import LatestOrders from "./widgets/latestOrders";
import { details } from "../../../../configs/details";
import { updateTicketsData } from "../../../../redux/tickets/ticketActions";
import useApp from "../../../../useApp";
import { useHistory } from "react-router-dom";
import MostViewedProducts from "./widgets/mostViewedProducts";
import MostPurchasedProduct from "./widgets/mostPurchasedProduct";
import Feedback from "./widgets/Feedback";
import MostSearchProduct from "./widgets/mostSearchedProduct";
import SearchedAndUnavailableProducts from "./widgets/searchedandUnavailableProducts";
import StockTable from "./widgets/inStockAndOutOfStockProducts";
import Traffic from "./widgets/Traffic";
import { Link } from "react-router-dom";

export default function ECommerce() {
  const dispatch = useDispatch();
  const [userDetail, setUserDetail] = useState('');
  const [newData, setNewData] = useState('');

  const { sessionExpired } = useApp();
  const history = useHistory();
  const getDashboardC = async () => {
    try {
      let products = await getDashboardCount();
     dispatch(addOrderDashboard(products.data));
      const getAllSellers = await getAllSeller({ page: 1, limit: 50 })
    } catch (err) {
    if (err?.response?.data?.message == "Invalid credential") {
        location.replace("/authentication/onboarding"); // temporary
      }
      if (err?.response?.status == 401) {
        sessionExpired();
      } else {
        IconNotification({
          type: "error",
          message: "Fail",
          description: err?.response?.data?.message,
        });
      }
    }
  };

  const getTicket = async () => {
    try {
      const res = await getTickets();
      dispatch(
        updateTicketsData(
          res.data.list.filter((item) => item.type == "announcement")
        )
      );
    } catch (err) {
      if (err?.response?.status == 401) {
        // sessionExpired();
      } else {
        IconNotification({
          type: "error",
          message: "Fail",
          description: err?.response?.data?.message,
        });
      }
    }
  };
  useEffect(() => {
    const userData = localStorage.getItem("matjar_user_data") ? JSON.parse(localStorage.getItem("matjar_user_data")) : null;
    setNewData(userData)
  }, [])
  localStorage.setItem("sellerData", JSON.stringify(newData));
  const sellerDetailData = JSON.parse(localStorage.getItem("sellerDetailData"));


  const getSellerData = async () => {
    try {
      const getSellerDetail = await getSellerById(sellerDetailData._id);
      const getAllSellers = await getAllSeller({ page: 1, limit: 1 })
     } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    getSellerData()
    if (sellerDetailData?.status == 'draft') {
      history.push('/authentication/onboarding')
    } else if (sellerDetailData?.status === 'pending') {
      history.push('/authentication/login')
    }
    getDashboardC();
    getTicket();
  }, []);
  useEffect(() => {
    const detailData = details ? JSON.parse(details) : null;
    // const detailData = details ? JSON.parse(details) : null
    setUserDetail(detailData);

  }, [details]);
  return (
    <Row gutter={[32, 0]}>
      <Col span={24}>
        <Row align="middle" justify="space-between">
          <Col md={12} span={24}>
            <h3>Welcome back, {`${userDetail?.firstName}`} 👋</h3>

            <p className="hp-p1-body hp-mb-0">
              Your current status and analytics are here
            </p>
          </Col>
          <Col className="hp-mt-sm-24">
            <Select
              style={{ width: "100%" }}
              defaultValue="this-mounth"
              id="month-select"
            >
              <Select.Option value="this-mounth">This Month</Select.Option>

              <Select.Option value="this-week">This Week</Select.Option>

              <Select.Option value="this-year">This Year</Select.Option>
            </Select>
          </Col>
        </Row>
      </Col>

      <Col span={24} className="hp-mt-32">
        <Row gutter={[32, 12]}>
          <Col span={24}>
            <Row gutter={[12, 32]}>
              <Col xl={4} md={12} span={24}>
                <Link to='/support'>
                  <UnreadMsgs />
                </Link>
              </Col>

              <Col xl={4} md={12} span={24}>
                <Link to='/orders'>
                  <AwaitingDispatch />
                </Link>
              </Col>

              <Col xl={4} md={12} span={24}>
                <Link to='/orders'>
                  <AwaitingOrders />
                </Link>
              </Col>

              <Col xl={4} md={12} span={24}>
                <Link to='/orders'>
                  <Sales />
                </Link>
              </Col>

              <Col xl={4} md={12} span={24}>
                <Link to='/orders'>
                  <Profit />
                </Link>
              </Col>

              <Col xl={4} md={12} span={24}>
                <Link to='/reviews'>
                  <Reviews />
                </Link>
              </Col>
            </Row>
          </Col>

          <Col span={24} className="hp-overflow-hidden">
            <Row gutter={[12, 12]}>
              <Col md={8} span={24}>
                <Link to='/orders'>
                  <SalesCard />
                </Link>
              </Col>
              <Col md={8} span={24}>
                <Link to='/listings'>
                  <ListingCard />
                </Link>
              </Col>
              <Col md={8} span={24}>
                <Link to='/support'>
                  <Announcements />
                </Link>
              </Col>
            </Row>
          </Col>

          <Col span={24} className="hp-overflow-hidden">
            <Row gutter={[12, 12]}>
              <Col md={8} span={24}>
                <Link to='/orders'>
                  <AllOrders />
                </Link>
              </Col>
              <Col md={16} span={24}>
                <Link to='/orders'>
                  <LatestOrders />
                </Link>
              </Col>
            </Row>
          </Col>
          <Col span={24} className="hp-overflow-hidden">
            <Row gutter={[12, 12]}>
              <Col md={15} span={24}>

                <MostViewedProducts />
              </Col>
              <Col md={9} span={24}>
                <MostPurchasedProduct />
              </Col>
            </Row>
          </Col>
          <Col span={24} className="hp-overflow-hidden">
            <Row gutter={[12, 12]}>
              <Col md={8} span={24}>
                <Link to='/reviews'>
                  <Feedback />
                </Link>
              </Col>
              <Col md={9} span={24}>
                <MostSearchProduct />
                {/* <MostPurchasedProduct /> */}
              </Col>
              <Col md={7} >
                <SearchedAndUnavailableProducts />
              </Col>
            </Row>
          </Col>
          <Col span={24} className="hp-overflow-hidden">
            <Row gutter={[12, 12]}>
              <Col md={14} span={24}>
                <StockTable />
              </Col>
              <Col md={9} span={24}>
                <Link to='/performance-traffic'>
                  <Traffic />
                </Link>
                {/* <MostSearchProduct /> */}
                {/* <MostPurchasedProduct /> */}
              </Col>

            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
