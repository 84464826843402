import * as actionTypes from "./orderTypes";

export const addOrderDashboard = (value) => {
  return {
    type: actionTypes.ADD_DASHBOARD_COUNT,
    payload: value,
  };
}

export const addOneOrder = (value) => {
  return {
    type: actionTypes.ADD_ONE_ORDER,
    payload: value,
  };
}
export const orderFilter = (value)=> {
return {
  type: actionTypes.ORDER_FILTER,
  payload: value
}
}