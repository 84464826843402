import { Card, Col, Rate, Row, Typography, Progress, } from 'antd'
import React, { useState } from 'react'
const { Text } = Typography;
const CustomerReviews = () => {
    const [topSellingProduct, setTopSellingProduct] = useState([
        {
            id: 1,
            product: 'Cleaning Gel',
            value: 242,
            percent: (242 / 500) * 100,
            color: 'hsla(33, 96%, 44%, 1)'
        },
        {
            id: 2,
            product: "Noco Boost",
            value: 212,
            percent: (212 / 500) * 100,
            color: 'hsla(34, 76%, 55%, 1)'
        },
        {
            id: 3,
            product: "Nexpow",
            value: 142,
            percent: (142 / 500) * 100,
            color: 'hsla(34, 76%, 66%, 1)'
        },
        {
            id: 4,
            product: 'Battery tender',
            value: 54,
            percent: (54 / 500) * 100,
            color: 'hsla(34, 76%, 77%, 1)'
        },
        {
            id: 5,
            product: 'STA Storage',
            value: 53,
            percent: (2307 / 4694) * 100,
            color: 'hsla(34, 76%, 89%, 1)'
        }
    ])
    return (
        <Card>
            <Row gutter={[32, 32]}>
                <Col span={18}>
                    <Text strong style={{ color: 'hsla(221, 43%, 11%, 1)', fontSize: '15px' }}>Customer Reviews</Text>
                </Col>
                <Col span={6} style={{ color: 'hsla(215, 18%, 34%, 1)', fontWeight: '600', fontSize: '14px' }}>
                    View All
                </Col>
            </Row>
            <Row>
                <Col span={6}>
                    <Text strong style={{ color: 'hsla(221, 43%, 11%, 1)', fontSize: '24px' }}>4.8</Text>
                </Col>
                <Col xs={0} lg={18} xl={0} style={{marginBottom:'13px', marginLeft:'5px'}}>
                    <Rate defaultValue={5} />
                    <Text style={{ color: 'hsla(215, 18%, 34%, 1)', fontSize: '12.9219px', marginLeft: '12px' }}>683 reviews</Text>
                </Col>
            </Row>
            <Row 
            style={{marginBottom:'13px'}}
            >
                <Col xs={24} lg={0} xl={24} style={{marginBottom:'13px'}}>
                    <Rate defaultValue={5} />
                    <Text style={{ color: 'hsla(215, 18%, 34%, 1)', fontSize: '12.9219px', marginLeft: '12px' }}>683 reviews</Text>
                </Col>
            </Row>
            {topSellingProduct.map((topSellingProduct) => {
                console.log("%c percent value", "background: purple; color: white", topSellingProduct.percent)
                return (
                    <Row style={{marginTop:'0px'}}>
                        <Col
                        //  style={{ marginTop: '8px' }}
                        //  xs={6} lg={24} xl={6}>
                        span={6}>
                            <Text >{topSellingProduct.product}  </Text>
                        </Col>
                        <Col 
                        // xs={14} lg={18} xl={14}
                        span={14} 
                        style={{ margin: '0px 8px 0px 0px' }}
                        >
                            <Progress strokeColor={topSellingProduct.color} percent={topSellingProduct.percent} showInfo={false} strokeWidth={10} />
                        </Col>
                        <Col
                        //  style={{ marginTop: '0px' }} 
                        //  xs={2} lg={4} xl={2}>
                        span={2}>
                            <p>{topSellingProduct.value}</p>
                        </Col>
                    </Row>
                )
            })}
        </Card>
    )
}

export default CustomerReviews
