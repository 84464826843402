/* eslint-disable */
import { useSelector } from "react-redux";
// import "~slick-carousel/slick/slick.css"; 
// import "~slick-carousel/slick/slick-theme.css";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import './configs/apiConfig';
import { Button, ConfigProvider } from "antd";
import COGNITO from "./configs/awsConfigs";
import { Amplify } from "aws-amplify";
import Router from "./router/Router";
import { Modal } from "antd";
import Cookies from "js-cookie";
Amplify.configure({
  aws_user_pools_id: COGNITO.ref.Auth.userPoolId,
  aws_user_pools_web_client_id: COGNITO.ref.Auth.userPoolWebClientId,
});
export default function App() {
 const customise = useSelector((state) => state.customise);
  const AuthStatus = useSelector((state) => state.auth.isAuthorized);
 const token = localStorage.getItem("token");
  if (!token) {
    localStorage.clear();
  }

  return (
    <div id="google_translate_element">
   
    <ConfigProvider direction={customise.direction}>
      {/* {!token && ( */}
      <Modal open={AuthStatus} footer centered closable={false}>
        <div className="session_modal">
          <p>Your session has been expired, please log in again.</p>
          <Button
            type="primary"
            onClick={() => {
              localStorage.clear();
              Cookies.remove("session");
              Cookies.remove("Token");
              Cookies.remove("email");
              window.location.reload();
              window.location.href = '/authentication/login'
            }}
          >
            Logout
          </Button>
        </div>
      </Modal>
      {/* )} */}
      <Router />
    </ConfigProvider>
    </div>
  );
}
