import React, { useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Layout, Row, Col } from "antd";
import HeaderHorizontal from "./components/header/HeaderHorizontal";
import MenuFooter from "./components/footer";
import ScrollTop from "./components/scroll-to-top";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { resetProductCounter } from "../redux/product/productActions";
const { Content } = Layout;

export default function HorizontalLayout(props) {
  const { children } = props;
  const [visible, setVisible] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const customise = useSelector((state) => state.customise);
   useEffect(() => {
    if (
      location.pathname == `/add-product` ||
      location.pathname == `/products`
    ) {
      dispatch(resetProductCounter());
    }  
}, [location.pathname]);
   return (
    <Layout
      className={`hp-app-layout hp-bg-color-dark-90 ${
        customise.navigationBg && "hp-app-layout-bg"
      }`}
    >
      <HeaderHorizontal visible={visible} setVisible={setVisible} />
      <Content
        // className="hp-mb-32"
        className="hp-content-main hp-mb-32">
        <Row justify="center">
          {customise.contentWidth == "full" && <Col span={24}>{children}</Col>}

          {customise.contentWidth == "boxed" && (
            <Col xxl={20} xl={22} span={24}>
              {children}
            </Col>
          )}
        </Row>
      </Content>
      <MenuFooter />
      <ScrollTop />
    </Layout>
  );
}
