import React,{useState} from 'react'
import { Card, Col, Row } from 'antd';
import TopbarCard from './TopbarCard';
import Cookies from "js-cookie";
import Announcement from './Announcement';
import ProductStatus from './ProductStatus';
import SalesTrend from './SalesTrend';
import OrderStatus from './OrderStatus';
import TopSellingCategory from './TopSellingCategory';
import TopSellingProduct from './TopSellingProduct';
import RecentTransaction from './RecentTransactions';
import LatestOrder from './LatestOrder';
import CustomerReviews from './CustomerReviews';
import VectorMap from './vectormap/VectorMap';
import AccountDetails from './AccountDetails';
import WithdrawalHistory from './WithdrawalHistory';
import { Link } from 'react-router-dom';
import UnreadMsgs from '../../../home/dashboard/ecommerce/widgets/unreadMsgs';
import AwaitingDispatch from '../../../home/dashboard/ecommerce/widgets/awaitingDispatch';
import AwaitingOrders from '../../../home/dashboard/ecommerce/widgets/awaitingOrders';
import Profit from '../../../home/dashboard/ecommerce/widgets/profit';
import Reviews from '../../../home/dashboard/ecommerce/widgets/reviews';
import SalesCard from '../../../home/dashboard/ecommerce/widgets/salesCard';
import Sales from '../../../home/dashboard/ecommerce/widgets/sales'
import AllOrders from '../../../home/dashboard/ecommerce/widgets/allOrders';
import ListingCard from '../../../home/dashboard/ecommerce/widgets/listingCard';
import MostViewedProducts from '../../../home/dashboard/ecommerce/widgets/mostViewedProducts';
import MostSearchProduct from '../../../home/dashboard/ecommerce/widgets/mostSearchedProduct';
import SearchedAndUnavailableProducts from '../../../home/dashboard/ecommerce/widgets/searchedandUnavailableProducts'
import StockTable from '../../../home/dashboard/ecommerce/widgets/inStockAndOutOfStockProducts'
import Traffic from '../../../home/dashboard/ecommerce/widgets/Traffic'
import EmailModal from '../../../components/modals/EmailModal';
import { loginCustomHeader } from '../../../../service/api';
const HomePage = () => {// State variables
  // const [showModal, setShowModal] = useState(true);
  const [showModal, setShowModal] = useState(true);
  const [newEmail, setNewEmail] = useState(false);
  
  const [cookieData, setCookieData] = useState();
  const [tokenData, setTokenData] = useState();
  const [isStatus, setIsStatus] = useState(false);
  const [loginToken, setLoginToken] = useState([]);

  console.log(Cookies.get("session"));
  const cookiesData = () => {
    setCookieData(JSON.parse(Cookies.get("session")));
    setTokenData(JSON.parse(Cookies.get("token")));
    console.log("modalStatus->", Cookies.get("modalStatus"));
    setLoginToken(JSON.parse(localStorage.getItem("matjar_user_data")));
  };
  console.log("cookies Data:", cookieData);
  console.log("Token Data", tokenData);
  console.log("cookies data cognito Id:", cookieData?.cognitoId);
  const dynamicSwitching = () => {
    if (Cookies.get("buyerToken") || localStorage.getItem("matjar_user_data")) {
      loginCustomHeader(JSON.parse(Cookies.get("buyerToken")) || loginToken.token)
        .then((res) => {
          console.log(res.data);
          if (!localStorage.getItem("matjar_user_data")) {
            // setLoggedIn(true);
            localStorage.setItem(
              "matjar_user_data",
              JSON.stringify({
                ...res.data,
                Email: JSON.parse(Cookies.get("buyerEmail")),
                token: JSON.parse(Cookies.get("buyerToken")) || loginToken.token,
              })
            );

            window.location.reload();
            // setTimeout(() => {
            // }, 1000);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      // window.location.reload();
    }
  };
   console.log(loginToken.token);
 console.log(Cookies.get("modalStatus") == "false");
  return (
    <>
      {Cookies.get("buyerToken") && Cookies.get("buyerModalStatus") == "false" && (
        <>
          <EmailModal
            isOpen={showModal}
            message={`Do you want to continue with this email ${JSON.parse(
              Cookies.get("buyerEmail")
            )} ?`}
            closeModal={() => {
              setShowModal(false);
              dynamicSwitching();
              setNewEmail(true);
              Cookies.set("buyerModalStatus", true);

              // setIsShow(false);
            }}
            newEmail={() => {
              setNewEmail(false);
              setShowModal(false);
              setIsStatus(true);
              Cookies.remove("Token");
              Cookies.remove("email");
              Cookies.remove("session");
              localStorage.removeItem("matjar_user_data");
              window.location.reload();
            }}
          />
        </>
      )}
    
    <Row gutter={[12, 32]}>
              <Col xl={4} md={12} span={24}>
              <Link to='/support'>
                <UnreadMsgs />
                </Link>
              </Col>

              <Col xl={4} md={12} span={24}>
              <Link to='/orders'>
                <AwaitingDispatch />
                </Link>
              </Col>

              <Col xl={4} md={12} span={24}>
              <Link to='/orders'>
                <AwaitingOrders />
                </Link>
              </Col>

              <Col xl={4} md={12} span={24}>
              <Link to='/orders'>
                <Sales />
                </Link>
              </Col>

              <Col xl={4} md={12} span={24}>
              <Link to='/orders'>
                <Profit />
                </Link>
              </Col>

              <Col xl={4} md={12} span={24}>
              <Link to='/reviews'>
                <Reviews />
                </Link>
              </Col>
            </Row>
      <Row gutter={[12, 12]}>
        {/* Content */}
        <Col xs={24} lg={24} xl={24}>
        
          </Col>
          <Col xs={24} lg={15} xl={18}>
       
          {/* Topbar */}
          {/* <TopbarCard /> */}
          {/* Sales Trend and order status */}
          <Row gutter={[10, 10]} style={{ marginTop: '15px' }}>
            {/* Sales Trend */}
            <Col xs={24} sm={24}  xl={15}>
              <Link to='/orders'>
              <SalesTrend />
              </Link>
            </Col>
            {/* Order Status */}
            <Col xs={24} md={0} lg={0} xl={9}>
            <Link to='/orders'>
              <OrderStatus />
              </Link>
             </Col>
            </Row>
            <Row gutter={[32,32]} style={{marginTop:'12px'}}>
              <Col xs={24} md={12} lg={12} xl={12}>
              <Link to='/orders'>
                <AllOrders />
                </Link>
              </Col>
              <Col xs={24} md={12} lg={12} xl={12}>
              <Link to='/listings'>
                <ListingCard />
                </Link>
              </Col>
            </Row>
          {/* ----- Sales Trend and order status  ----*/}
          <Row gutter={[10, 10]} style={{ marginTop: '15px' }}>
            {/* Top Selling Category */}
            <Col xs={24} md={12} lg={12} xl={10}>

              <TopSellingCategory />
            </Col>
            <Col xs={0} md={12} lg={12} xl={0}>
            <Link to='/orders'>
              <OrderStatus />
              </Link>
            </Col>
            {/* Top Selling Product */}
            <Col xs={24} lg={24} xl={14}>
            <Link to='/orders'>
              <TopSellingProduct />
              </Link>
            </Col>
          </Row>
          <Row gutter={[18, 18]} style={{ marginTop: '15px' }}>
            {/* Top Selling Category */}
            <Col xs={24} md={24} lg={24} xl={14}>
            <Link to='/orders'>
              <LatestOrder />
              </Link>
            </Col>
            {/* <Col xs={0} lg={24} xl={0}>
                  <RecentTransaction />
                </Col> */}
            {/* Top Selling Product */}
            <Col xs={24} lg={0} xl={10}>
            <Link to='/reviews'>
              <CustomerReviews />
              </Link>
              {/* <TopSellingProduct /> */}
            </Col>
          </Row>
          <Row style={{marginTop:'12px'}}> 
          <Col md={24} span={24}>
              <MostViewedProducts />
            </Col>
          </Row>
        </Col>
        {/*  Sidebar  */}
        <Col xs={24} lg={9} xl={6} >
          <Row >
            <Col xs={24} lg={24}>
              <Row gutter={[24,24]}>
                <Col xs={24} md={12} lg={24}>
                <Link to='/support'>
                  <Announcement />
                  </Link>
                </Col>
                <Col xs={24} md={12} lg={24}>
                  <ProductStatus />
                </Col>
                <Col xs={24} lg={24} xl={24}>
                  
                  <RecentTransaction />
                </Col>
                <Col xs={0} lg={24} xl={0}>
                <Link to='/reviews'>
                  <CustomerReviews />
                </Link>
                </Col>
                <Col md={24} span={24}>
                <MostSearchProduct />
                {/* <MostPurchasedProduct /> */}
              </Col>
              <Col md={24} span={24} >
                <SearchedAndUnavailableProducts />
              </Col>
              </Row>
            </Col>
          </Row>
          {/* <Row style={{ backgroundColor: 'blue', marginTop: '6%' }}>
            <Col xs={0} lg={24} xl={0} >
              <CustomerReviews />
            </Col>

          </Row> */}

        </Col>
      </Row>
      <Row style={{marginTop:'12px'}}>
      <Col span={24} className="hp-overflow-hidden">
            <Row gutter={[12, 12]}>
              <Col md={14} span={24}>
                <StockTable />
              </Col>
              <Col md={9} span={24}>
                <Link to='/performance-traffic'>
                  <Traffic />
                </Link>
                {/* <MostSearchProduct /> */}
                {/* <MostPurchasedProduct /> */}
              </Col>

            </Row>
          </Col>
          </Row>
      <Row gutter={[12, 12]} style={{ marginTop: '16px' }}>
        <Col xs={24} xl={10} xxl={11}>
          <Card >
            <VectorMap />
          </Card>
        </Col>
        <Col xs={24}
        //  sm={8}
         sm={24}
         md={8} xl={5} xxl={4}>
          <Card>
            <AccountDetails />
          </Card >
        </Col>
        <Col xs={24} 
        // sm={16}
        sm={24}
         md={16} xl={9} xxl={9}>
          <Card >
            <WithdrawalHistory />
          </Card>
        </Col>
        
      </Row>
    </>
  )
}

export default HomePage