import axios from "axios";
// export const BASE_URL = 'http://localhost:4000/api/v1'
export const BASE_URL = 'https://api-matjars-qa.dspaco.com/api/v1/';
  
const instance = axios.create({
  baseURL: BASE_URL,
  timeout: 10000,
  // headers: {
  //   "Content-Type": "application/json",
  //   // Authorization: `Bearer ${token}`
  // },
});

instance.interceptors.request.use(
  (config) => {
     const token = localStorage.getItem("token");
    config.headers = { ...config.headers, Authorization: `Bearer ${token}` };
    return config;
     },
  (error) => {
    console.log("error from instancww", error)
    // handle the error
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    console.log('Response:', response);
   // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (response.data.status == "UnAuth") {
      localStorage.removeItem("token");
      window.location.replace("/login");
    } else {
      if (response?.isUnAuthorized) {
        window.location.replace("/login");
        return;
      }

      return response;
    }
    if (response?.isUnAuthorized) {
      window.location.replace("/login");
      return;
    }
    return response;
  },
  function (error) {
   // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default instance;
