import React, { useState,useEffect } from "react";


import { notification } from "antd";
import {
  RiCloseFill,
  RiCheckboxCircleFill,
  RiErrorWarningFill,
} from "react-icons/ri";

export default function IconNotification(props) {
  // const [checkedCode, setCheckedCode] = useState(false);
  // const [codeClass, setCodeClass] = useState(false);

  // function toggleChecked() {
  //   setTimeout(() => setCodeClass(!codeClass), 100);
  //   setCheckedCode(!checkedCode);
  // }

 

  const openNotification = () => {
    notification.open({
      message: props.message || "Success",
      description: props.description || "Success message.",
      icon: props.type=='success'?<RiCheckboxCircleFill style={{ color: "#00F7BF" }} />:props.type=='error'? <RiErrorWarningFill style={{ color: "#FF0022" }} />:props.type=='warning'?<RiErrorWarningFill style={{ color: "#FFC700" }} />:<RiErrorWarningFill style={{ color: "#1BE7FF" }} />,
      closeIcon: (
        <RiCloseFill className="remix-icon hp-text-color-black-80 hp-text-color-dark-30" size={24} />
      ),
    });
  };

 

  return (
    // Use the hook here somehow
    <>{openNotification()}</>
    
  )

}
