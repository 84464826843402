import './configs/apiConfig';
import React, { Suspense } from "react";

import { BrowserRouter } from "react-router-dom";
// import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import store, { persistor } from "./redux/store";

import "./assets/icons/remixicon.css";
import "./assets/less/yoda-theme.less";
import {PersistGate} from 'redux-persist/integration/react'
import './i18next';
import App from "./App";
import "./index.css";
import { createRoot } from 'react-dom/client';
const container =  document.getElementById("root")
const root = createRoot(container); 
root.render(
  <Suspense fallback="loading">
    <Provider store={store}>
      <PersistGate persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
      </PersistGate>
    </Provider>
  </Suspense>
);
