import * as actionTypes from "./businessTypes";

let INITIAL_STATE = {
  onBoardDataBussiness: [
    {
      userData: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        dob: "",
        gender: "",
        profilePic: "",
        type: "seller",
      },
      type: "business",
      logo: "",
      documents: [],
      withdrawType: "",
      Withdraw: {
        accountTitle: "",
        accountNumber: "",
        bankName: "",
        branchCode: "",
        IBAN: "",
      },
      status: "",
      statusReason: "",
      storeName: "",
      selectedPersonalDoc: "Emirates ID",
      selectedBusinessDoc: "Trade License",
    }
  ],
  uploadedDocuments: [],
  counterbussiness: 0,
};

const onboardBusinessReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'ADD_ONBOARD_BUSSINESS':
      // return state.onBoardDataBussiness.push(action.payload)
      return {
        ...state,
        // ...action.payload
        // onBoardDataBussiness: action.payload
        onBoardDataBussiness:  [...state?.onBoardDataBussiness]
      };
    case actionTypes.INCREMENT_ONBOARD_COUNTER_BUSSINESS:
      return {
        ...state,
        // counterbussiness: 0,
        counterbussiness: state.counterbussiness + 1,
      };
    case actionTypes.DECREMENT_ONBOARD_COUNTER_BUSSINESS:
      return {
        ...state,
        counterbussiness: state.counterbussiness - 1,
      };
    case actionTypes.RESET_ONBOARD_COUNTER_BUSSINESS:
      return {
        ...state,
        counterbussiness: 0,
      };
    case actionTypes.UPDATE_TYPE_BUSSINESS:
      return { ...state, onBoardDataBussiness: action.payload };

    case actionTypes.ONBOARD_DOCUMENTS_BUSSINESS:
      return { ...state, uploadedDocuments: action.payload };
    default:
      return state;
  }
};

export default onboardBusinessReducer;
