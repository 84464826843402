import * as actionTypes from "./ticketTypes";

const INITIAL_STATE = {
  ticketsData: [],
  isLoading: false,
};

const ticketReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_TICKET_DATA:
      return {
        ...state,
        ticketsData: action.payload,
      };
    case actionTypes.IS_TICKET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
};

export default ticketReducer;
