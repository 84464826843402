import { Avatar, Card, Col, Divider, List, Row, Skeleton, Typography } from 'antd';
import React, { useState } from 'react';

const { Text } = Typography;
import InfiniteScroll from 'react-infinite-scroll-component';
// import './recenttransaction.css';
const RecentTransaction = () => {
    const [data, setData] = useState([
        {
            id: 1,
            avatar: 'https://images.pexels.com/photos/7956286/pexels-photo-7956286.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
            firstName: 'Ahmed',
            lastname: 'Al Fahim',
            transactionStatus: 'Purchased',
            orderAmmount: 4567,
            transactionAmount: 628.00,
            transatcionDate: ' 2 Jan 2023'
        },
        {
            id: 2,
            avatar: 'https://images.unsplash.com/photo-1615899486509-84e2c782b0da?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=391&q=80',
            firstName: 'Fatima',
            lastname: 'Khoury',
            transactionStatus: 'Purchased',
            orderAmmount: 4567,
            transactionAmount: 628.00,
            transatcionDate: ' 2 Jan 2023'
        },
        {
            id: 3,
            avatar: 'https://images.unsplash.com/photo-1571513722275-4b41940f54b8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
            firstName: 'Khalid',
            lastname: 'Ibrahim',
            transactionStatus: 'Withdraw',
            orderAmmount: 4567,
            transactionAmount: 628.00,
            transatcionDate: ' 2 Jan 2023'
        },
        {
            id: 4,
            avatar: 'https://images.pexels.com/photos/3785079/pexels-photo-3785079.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
            firstName: 'Aisha',
            lastname: 'Al Maktoum',
            transactionStatus: 'Purchased',
            orderAmmount: 4567,
            transactionAmount: 628.00,
            transatcionDate: ' 2 Jan 2023'
        },
        {
            id: 5,
            avatar: 'https://images.pexels.com/photos/5215024/pexels-photo-5215024.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
            firstName: 'Mohammad',
            lastname: 'Hassan',
            transactionStatus: 'Purchased',
            orderAmmount: 4567,
            transactionAmount: 628.00,
            transatcionDate: ' 2 Jan 2023'
        },
        {
            id: 6,
            avatar: 'https://images.pexels.com/photos/1036623/pexels-photo-1036623.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
            firstName: 'Mariam',
            lastname: 'Al Qasimi',
            transactionStatus: 'Purchased',
            orderAmmount: 4567,
            transactionAmount: 628.00,
            transatcionDate: ' 2 Jan 2023'
        },
        {
            id: 7,
            avatar: 'https://images.unsplash.com/photo-1566492031773-4f4e44671857?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
            firstName: 'Abdullah',
            lastname: 'Khalifa',
            transactionStatus: 'Purchased',
            orderAmmount: 4567,
            transactionAmount: 628.00,
            transatcionDate: ' 2 Jan 2023'
        },
        {
            id: 8,
            avatar: 'https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
            firstName: 'Noora',
            lastname: 'Al Shehhi',
            transactionStatus: 'Withdraw',
            orderAmmount: 4567,
            transactionAmount: 628.00,
            transatcionDate: ' 2 Jan 2023'
        },
        {
            id: 9,
            avatar: 'https://images.unsplash.com/photo-1590926938512-c0d7e5c39abd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=425&q=80',
            firstName: 'Saif',
            lastname: 'Al Hamed',
            transactionStatus: 'Purchased',
            orderAmmount: 4567,
            transactionAmount: 628.00,
            transatcionDate: ' 2 Jan 2023'
        },
        {
            id: 10,
            avatar: 'https://images.unsplash.com/photo-1524504388940-b1c1722653e1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
            firstName: 'Sara',
            lastname: 'Al Mansoori',
            transactionStatus: 'Purchased',
            orderAmmount: 4567,
            transactionAmount: 628.00,
            transatcionDate: ' 2 Jan 2023'
        },
        {
            id: 11,
            avatar: 'https://images.unsplash.com/photo-1544717305-2782549b5136?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
            firstName: 'Jisoo',
            lastname: 'Calik',
            transactionStatus: 'Withdraw',
            orderAmmount: 4567,
            transactionAmount: 628.00,
            transatcionDate: ' 2 Jan 2023'
        },
        {
            id: 12,
            avatar: 'https://images.unsplash.com/photo-1573496359142-b8d87734a5a2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=388&q=80',
            firstName: 'Somiya',
            lastname: 'Baeck',
            transactionStatus: 'Purchased',
            orderAmmount: 4567,
            transactionAmount: 628.00,
            transatcionDate: ' 2 Jan 2023'
        },
        {
            id: 13,
            avatar: 'https://images.unsplash.com/photo-1586188389405-bd05314a47ac?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=415&q=80',
            firstName: 'Jaemin',
            lastname: 'Ben',
            transactionStatus: 'Purchased',
            orderAmmount: 4567,
            transactionAmount: 628.00,
            transatcionDate: ' 2 Jan 2023'
        },
        {
            id: 14,
            avatar: 'https://images.unsplash.com/photo-1600603405959-6d623e92445c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
            firstName: 'Ahmed',
            lastname: 'Khalifa',
            transactionStatus: 'Purchased',
            orderAmmount: 4567,
            transactionAmount: 628.00,
            transatcionDate: ' 2 Jan 2023'
        },
        {
            id: 15,
            avatar: 'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
            firstName: 'Rashid',
            lastname: 'Al Mazrouei',
            transactionStatus: 'Purchased',
            orderAmmount: 4567,
            transactionAmount: 628.00,
            transatcionDate: ' 2 Jan 2023'
        },
        {
            id: 16,
            avatar: 'https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
            firstName: 'Mariam',
            lastname: 'Ahmed',
            transactionStatus: 'Withdraw',
            orderAmmount: 4567,
            transactionAmount: 628.00,
            transatcionDate: ' 2 Jan 2023'
        },
        {
            id: 17,
            avatar: 'https://images.unsplash.com/photo-1615899486509-84e2c782b0da?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=391&q=80',
            firstName: 'Sultan',
            lastname: 'Al Khouri',
            transactionStatus: 'Purchased',
            orderAmmount: 4567,
            transactionAmount: 628.00,
            transatcionDate: ' 2 Jan 2023'
        },
        {
            id: 18,
            avatar: 'https://images.unsplash.com/photo-1573496359142-b8d87734a5a2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=388&q=80',
            firstName: 'Salma',
            lastname: 'Ibrahim',
            transactionStatus: 'Purchased',
            orderAmmount: 4567,
            transactionAmount: 628.00,
            transatcionDate: ' 2 Jan 2023'
        },
        {
            id: 19,
            avatar: 'https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
            firstName: 'Khalid',
            lastname: 'Al Hammadi',
            transactionStatus: 'Purchased',
            orderAmmount: 4567,
            transactionAmount: 628.00,
            transatcionDate: ' 2 Jan 2023'
        },
        {
            id: 20,
            avatar: 'https://images.pexels.com/photos/7956286/pexels-photo-7956286.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
            firstName: 'Saif',
            lastname: 'Al Qasimi',
            transactionStatus: 'Purchased',
            orderAmmount: 4567,
            transactionAmount: 628.00,
            transatcionDate: ' 2 Jan 2023'
        },
        {
            id: 21,
            avatar: 'https://images.pexels.com/photos/5215024/pexels-photo-5215024.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
            firstName: 'Jisoo',
            lastname: 'Calik',
            transactionStatus: 'Withdraw',
            orderAmmount: 4567,
            transactionAmount: 628.00,
            transatcionDate: ' 2 Jan 2023'
        }, {
            id: 22,
            avatar: 'https://images.unsplash.com/photo-1573496359142-b8d87734a5a2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=388&q=80',
            firstName: 'Somiya',
            lastname: 'Baeck',
            transactionStatus: 'Purchased',
            orderAmmount: 4567,
            transactionAmount: 628.00,
            transatcionDate: ' 2 Jan 2023'
        },
        {
            id: 23,
            avatar: 'https://images.unsplash.com/photo-1586188389405-bd05314a47ac?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=415&q=80',
            firstName: 'Aisha',
            lastname: 'Al Nuaimi',
            transactionStatus: 'Purchased',
            orderAmmount: 4567,
            transactionAmount: 628.00,
            transatcionDate: ' 2 Jan 2023'
        },
        {
            id: 24,
            avatar: 'https://images.unsplash.com/photo-1600603405959-6d623e92445c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
            firstName: 'Khalid',
            lastname: 'Al Qassimi',
            transactionStatus: 'Purchased',
            orderAmmount: 4567,
            transactionAmount: 628.00,
            transatcionDate: ' 2 Jan 2023'
        },
        {
            id: 25,
            avatar: 'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
            firstName: 'Noora',
            lastname: 'Al Hamidi',
            transactionStatus: 'Purchased',
            orderAmmount: 4567,
            transactionAmount: 628.00,
            transatcionDate: ' 2 Jan 2023'
        },
        {
            id: 26,
            avatar: 'https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
            firstName: 'Rashid',
            lastname: 'Al Fahim',
            transactionStatus: 'Withdraw',
            orderAmmount: 4567,
            transactionAmount: 628.00,
            transatcionDate: ' 2 Jan 2023'
        },
        {
            id: 27,
            avatar: 'https://images.unsplash.com/photo-1615899486509-84e2c782b0da?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=391&q=80',
            firstName: 'Sara',
            lastname: 'Al Zaabi',
            transactionStatus: 'Purchased',
            orderAmmount: 4567,
            transactionAmount: 628.00,
            transatcionDate: ' 2 Jan 2023'
        },
        {
            id: 18,
            avatar: 'https://images.unsplash.com/photo-1573496359142-b8d87734a5a2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=388&q=80',
            firstName: 'Saif',
            lastname: 'Khalfan',
            transactionStatus: 'Purchased',
            orderAmmount: 4567,
            transactionAmount: 628.00,
            transatcionDate: ' 2 Jan 2023'
        },
        {
            id: 29,
            avatar: 'https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

            firstName: 'Fatima',
            lastname: 'Al Maktoum',
            transactionStatus: 'Purchased',
            orderAmmount: 4567,
            transactionAmount: 628.00,
            transatcionDate: ' 2 Jan 2023'
        },
        {
            id: 30,
            avatar: 'https://images.pexels.com/photos/7956286/pexels-photo-7956286.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
            firstName: 'Luhan',
            lastname: 'Maek',
            transactionStatus: 'Purchased',
            orderAmmount: 4567,
            transactionAmount: 628.00,
            transatcionDate: ' 2 Jan 2023'
        },
        {
            id: 31,
            avatar: 'https://images.pexels.com/photos/5215024/pexels-photo-5215024.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
            firstName: 'Sara',
            lastname: 'Al Mazrouei',
            transactionStatus: 'Withdraw',
            orderAmmount: 4567,
            transactionAmount: 628.00,
            transatcionDate: ' 2 Jan 2023'
        },
    ]);
    const styless = {
        // backgroundColor: 'red',
        // color: 'white',
        '@media (maxWidth: 900px)': {
            backgroundColor: 'blue',
            color: 'black'
        }
    };
    return (
        <Card
            // style={{marginTop:'12px'}}
            style={styless}
            bodyStyle={{ padding: '12px' }}
        >
            <Text style={{ fontWeight: '900', color: 'hsla(221, 43%, 11%, 1)' }}>Recent Transaction</Text>
            <div
                id="scrollableDiv"
                className='scrolll'

                style={{
                    // backgroundColor:'yellow',
                    height: '620px',
                    overflow: 'auto',
                }}
            >
                <InfiniteScroll
                    dataLength={data.length}
                    // next={loadMoreData}
                    hasMore={data.length < 50}
                    endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
                    scrollableTarget="scrollableDiv"
                >
                    {data.map((item) => {
                        return (
                            <Row style={{ marginTop: '10px', width: '100%', }}>
                                <Col xs={17} md={18} lg={18}>
                                    <Row>
                                        <Col xl={4} style={{ margin: '0 0' }}>
                                            <Avatar src={item.avatar} style={{ width: '32.6px', height: '32.6px' }} />
                                        </Col>
                                        <Col xl={20}>
                                            <Row style={{ marginLeft: '5px' }}>
                                                <Col  >
                                                    <Text strong style={{ fontSize: '12.5px' }}>{item.firstName}  {item.lastname}</Text>
                                                </Col>
                                                <Col style={{
                                                    marginLeft: '7px',
                                                }}>
                                                    <Text style={{ color: '#475467', fontSize: '12.5px' }}>{item.transactionStatus}</Text>
                                                </Col>
                                            </Row>
                                            <Row
                                                style={{ marginLeft: '5px' }}
                                            >
                                                <Col>
                                                    <Text style={{ color: '#475467', fontSize: '12.5px' }}>Order {item.orderAmmount}</Text>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={5} md={6} xl={6} style={{ textAlign: 'right' }}>
                                    <Row>
                                        <Col span={24} style={{ color: 'hsla(153, 96%, 30%, 1)', fontSize: '13.5px' }}>
                                            + AED {item.transactionAmount}
                                        </Col>
                                        {/* </Row> */}
                                        {/* <Row style={{float:''}}> */}
                                        <Col span={24} style={{ color: '#475467', fontSize: '11.5px' }}>
                                            {item.transatcionDate}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        )
                    })}
                </InfiniteScroll>
            </div>
        </Card>
    );
};
export default RecentTransaction;