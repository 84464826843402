import React, { useState } from "react";

import { Card, Row, Col, Divider, Button } from "antd";
import { useSelector } from "react-redux";
import { toTitleCase } from "../../../../../configs/helper";

export default function ListingCard() {
  const useProductRedux = useSelector((state) => state.orders.dashboardCount);

  const statuses = [
    { _id: "draft" },
    { _id: "withQuestions" },
    { _id: "fixedPrice" },
    { _id: "auction" },
    { _id: "onSale" },
  ];

  const formattedStatuses = {
    draft: "Draft",
    withQuestions: "With Questions",
    fixedPrice: "Fixed Price",
    auction: "Auction",
    onSale: "On Sale",
  };

  const handleRedirectToDrafts = () => {
    // Add code to redirect to /drafts-listings
    window.location.href = "/drafts-listings";
  };

  return (
    <Card
      style={{ minHeight: "421px" }}
      className="hp-border-color-black-40 hp-card-6 hp-chart-text-color"
    >
      <Row>
        <Col className="hp-mb-16" span={24}>
          <Row justify="space-between">
            <Row align="bottom">
              <h4 className="hp-mr-8">Listings</h4>
            </Row>
          </Row>
        </Col>

        <Col span={24}>
          <Button
            type="link"
            className="hp-p-0"
            href="/add-product"
          >
            Create Product
          </Button>
          {statuses.map((item) => (
            <>
              <Row>
                <Col md={12} span={12}>
                  {item._id === "draft" ? (
                    <Button
                      type="link"
                      className="hp-p-0"
                      onClick={handleRedirectToDrafts}
                      color="#2d3436"
                    >
                      Draft
                    </Button>
                  ) : (
                    useProductRedux !== undefined &&
                    useProductRedux.productStatus.find(
                      (item1) => item1._id == item._id
                    )
                      ? formattedStatuses[
                          useProductRedux.productStatus.find(
                            (item1) => item1._id == item._id
                          )._id
                        ]
                      : formattedStatuses[item._id]
                  )}
                </Col>
                <Col md={12} span={12} className="hp-text-right">
                  {useProductRedux !== undefined &&
                  useProductRedux.productStatus.find(
                    (item1) => item1._id == item._id
                  )
                    ? useProductRedux.productStatus.find(
                        (item1) => item1._id == item._id
                      ).count
                    : 0}
                </Col>
              </Row>
              <Divider className="hp-my-12" />
            </>
          ))}
          <Divider className="hp-my-12" />
        </Col>
      </Row>
    </Card>
  );
}
