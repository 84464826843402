import * as actionTypes from "./onboardTypes";

const INITIAL_STATE = {
  onBoardData: [
    {
      id: 0,
      userData: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        dob: "",
        gender: "",
        profilePic: "",
        type: "seller",
      },
      type: "",

      // type: "individual",
      logo: "",
      documents: [],
      withdrawType: "",
      Withdraw: {
        accountTitle: "",
        accountNumber: "",
        bankName: "",
        branchCode: "",
        IBAN: "",
      },
      status: "",
      statusReason: "",
      storeName: "",
      selectedPersonalDoc: "Emirates ID",
      selectedBusinessDoc: "Trade License",
    }
  ],
  uploadedDocuments: [],
  counter: 0,
};

const customiseReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.ADD_ONBOARD:
       return {
        ...state,
        onBoardData: [...state?.onBoardData]
        // onBoardData:  [...state.onBoardData , action.payload] ,
      };
    case actionTypes.INCREMENT_ONBOARD_COUNTER:
      return {
        ...state,
        counter: state.counter + 1,
      };
    case actionTypes.DECREMENT_ONBOARD_COUNTER:
      return {
        ...state,
        counter: state.counter - 1,
      };
    case actionTypes.RESET_ONBOARD_COUNTER:
      return {
        ...state,
        counter: 0,
      };
    case actionTypes.UPDATE_TYPE:
      return { ...state, onBoardData: action.payload };

    case actionTypes.ONBOARD_DOCUMENTS:
      return { ...state, uploadedDocuments: action.payload };
    default:
      return state;
  }
};

export default customiseReducer;
