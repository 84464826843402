import { lazy } from "react";

const PagesRoutes = [
  // {
  //   path: "/",
  //   component: lazy(() => import("../../view/home/dashboard/ecommerce")),
  //   layout: "HorizontalLayoutPublic",
  //   private: false,
  // },
  {
    path:'/old-dashboard',
    component: lazy(() => import("../../view/home/dashboard/ecommerce")),
      layout: "HorizontalLayout",
      private: false,
  },
  {
    path: "/authentication/login",
    component: lazy(() => import("../../view/pages/authentication/login")),
    layout: "HorizontalLayoutPublic",
    private: false,
  },
  {
    path: "/authentication/recover-password",
    component: lazy(() =>
      import("../../view/pages/authentication/recover-password")
    ),
    layout: "HorizontalLayoutPublic",
    private: false,
  },
  {
    path: "/waiting-for-approval",
    component: lazy(() =>
      import("../../view/pages/authentication/adminapproval")
    ),
    layout: "HorizontalLayoutPublic",
    private: false,
  },
  {
    path: "/authentication/register",
    component: lazy(() => import("../../view/pages/authentication/register")),
    layout: "HorizontalLayoutPublic",
    private: false,
  },
  {
    path: "/authentication/reset-password",
    component: lazy(() =>
      import("../../view/pages/authentication/reset-password")
    ),
    layout: "HorizontalLayoutPublic",
    private: false,
  },
  {
    path: "/authentication/onboarding",
    component: lazy(() => import("../../view/pages/authentication/onboarding/index.jsx")),
    layout: "HorizontalLayoutPublic",
    private: false,
  },

  {
    path: "/products/:language?",
    component: lazy(() => import("../../view/pages/listings/index")),

    // component: lazy(() => import("../../view/pages/products/view")),
    layout: "HorizontalLayout",
    private: true,
  },
  {
    path: "/buyer",
    component: lazy(() => import("../../view/pages/buyer/index")),
    layout: "HorizontalLayout",
    private: true,
  },
  {
    path: "/buyer-register",
    component: lazy(() => import("../../view/pages/buyer/buyerRegister")),
    layout: "HorizontalLayout",
    private: true,
  },
  {
    path: "/buyer-login",
    component: lazy(() => import("../../view/pages/buyer/buyerLogin")),
    layout: "HorizontalLayout",
    private: true,
  },
  {
    path: "/seller-dashboard/:language?",
    component: lazy(() => import("../../view/pages/seller-dashboard/view")),
    layout: "HorizontalLayout",
    private: true,
  },
  {
    path: "/seller-dashboard/homepage/:language?",
    component: lazy(() =>
      import("../../view/pages/seller-dashboard/cards/HomePage.jsx")
    ),
    layout: "HorizontalLayout",
    private: true,
  },
  {
    path: "/seller-dashboard/product/:language?",
    component: lazy(() =>
      import("../../view/pages/seller-dashboard/cards/Product/Product.jsx")
    ),
    layout: "HorizontalLayout",
    private: true,
  },
  {
    path: "/seller-dashboard/category/:language?",
    component: lazy(() =>
      import("../../view/pages/seller-dashboard/cards/Category/Category.jsx")
    ),
    layout: "HorizontalLayout",
    private: true,
  },
  {
    path: "/seller-dashboard/orders/:language?",
    component: lazy(() =>
      import("../../view/pages/seller-dashboard/cards/Order/Orders.jsx")
    ),
    layout: "HorizontalLayout",
    private: true,
  },
  {
    path: "/add-product/:id/:language?",
    component: lazy(() => import("../../view/pages/products/add")),
    layout: "HorizontalLayout",
    private: true,
  },
  {
    path: "/preview-product/:id/:language?",
    component: lazy(() => import("../../view/pages/add-product-new/add/PreviewProducts/index")),
    layout: "HorizontalLayout",
    private: true,
  },
  {
    path: "/add-product/:language?",
    component: lazy(() => import("../../view/pages/products/add")),
    layout: "HorizontalLayout",
    private: true,
  },
  {
    // path: "/add-product-new/:language?",
    path: "/add-product-new/:id?/:language?",
    component: lazy(() => import("../../view/pages/add-product-new/add")),
    layout: "HorizontalLayout",
    private: true,
  },
  {
    path: "/product/preview/:id/:language?",
    component: lazy(() =>
      import("../../view/pages/products/add/productPreview")
    ),
    layout: "HorizontalLayout",
    private: true,
  },
  {
    path: "/apps/ecommerce/product-detail/:language?",
    component: lazy(() => import("../../view/pages/searchProduct")),
    layout: "VerticalLayout",
  },
  {
    path: "/apps/ecommerce/product-detail/:id/:language?",
    component: lazy(() =>
      import("../../view/pages/searchProduct/detail/Detail")
    ),
    layout: "HorizontalLayout",
    private: true,
    // layout: "VerticalLayout",
  },
  {
    path: "/apps/ecommerce/shop/:language?",
    component: lazy(() => import("../../view/pages/searchProduct")),
    layout: "VerticalLayout",
    private: true,
  },

  {
    path: "/productss/:language?",
    component: lazy(() => import("../../view/pages/listings/index")),
 
    // component: lazy(() => import("../../view/pages/productss/view")),
    layout: "HorizontalLayout",
    private: true,
  },
  {
    path: "/searchproduct/:langugae?",
    component: lazy(() =>
      import("../../view/pages/searchProduct/SearchDataDisplay")
    ),
    layout: "HorizontalLayout",
    private: true,
  },
  {
    path: `/add-ticket/:id/:language?`,
    component: lazy(() => import("../../view/pages/support/comment/index")),
    layout: "HorizontalLayout",
    private: true,
  },

  // /add-product/select-product
  // /add-product/step-1
  // /add-product/step-2
  // /add-product/preview

  {
    path: "/orders/:orderId/product/:productId/:language?",
    component: lazy(() => import("../../view/pages/orders/detail")),
    layout: "HorizontalLayout",
    private: true,
  },
  {
    path: "/orders/:language?",
    component: lazy(() => import("../../view/pages/orders/view")),
    layout: "HorizontalLayout",
    exact: true,
    private: true,
  },

  {
    path: "/orderss/:language?",
    component: lazy(() => import("../../view/pages/orderss/view")),
    layout: "HorizontalLayout",
    private: true,
  },

  {
    path: "/order-details/:language?",
    component: lazy(() => import("../../view/pages/orderss/detail")),
    layout: "HorizontalLayout",
    private: true,
  },

  {
    path: "/reviews/:langugage?",
    component: lazy(() => import("../../view/pages/reviews/view")),
    layout: "HorizontalLayout",
    private: true,
  },

  {
    path: "/finance/:language?",
    component: lazy(() => import("../../view/pages/finance/view")),
    layout: "HorizontalLayout",
    private: true,
  },
  {
    path: "/payments/:language?",
    component: lazy(() => import("../../view/pages/payments/index")),
    layout: "HorizontalLayout",
    private: true,
  },
  {
    path: "/payments-alltransactions/:language?",
    component: lazy(() => import("../../view/pages/payments/AllTransaction")),
    layout: "HorizontalLayout",
    private: true,
  },
  {
    path: "/payments-summary/:language?",
    component: lazy(() => import("../../view/pages/payments/Summary")),
    layout: "HorizontalLayout",
    private: true,
  },
  {
    path: "/marketing/:language?",
    component: lazy(() => import("../../view/pages/marketing/view")),
    layout: "HorizontalLayout",
    private: true,
  },
  {
    path: "/performance/:language?",
    component: lazy(() => import("../../view/pages/performance/view")),
    layout: "HorizontalLayout",
    private: true,
  },
  {
    path: "/performance-summary/:language?",
    component: lazy(() => import("../../view/pages/performance/summary/Summary")),
    layout: "HorizontalLayout",
    private: true,
  },
  {
    path: "/performance-sales/:language?",
    component: lazy(() => import("../../view/pages/performance/sales/Sales")),
    layout: "HorizontalLayout",
    private: true,
  },
  {
    path: "/performance-service-metrics/:language?",
    component: lazy(() => import("../../view/pages/performance/service-metrics/ServiceMetrics")),
    layout: "HorizontalLayout",
    private: true,
  },
  {
    path: "/performance-traffic/:language?",
    component: lazy(() => import("../../view/pages/performance/traffic/index")),
    layout: "HorizontalLayout",
    private: true,
  },
  {
    path: "/store/:language?",
    component: lazy(() => import("../../view/pages/Store/view/index")),
    layout: "HorizontalLayout",
    private: true,
  },
  {
    path: "/subcriptions/:language?",
    component: lazy(() => import("../../view/pages/marketing/Promotions/index")),
    layout: "HorizontalLayout",
    private: true,
  },
  {
    path: "/social/:language?",
    component: lazy(() => import("../../view/pages/marketing/Social/index")),
    layout: "HorizontalLayout",
    private: true,
  },
  {
    path: "/reports/:language?",
    component: lazy(() => import("../../view/pages/reports/view")),
    layout: "HorizontalLayout",
    private: true,
  },
  {
    path: "/listings/:language?",
    component: lazy(() => import("../../view/pages/listings/index")),
    layout: "HorizontalLayout",
    private: true,
  },
  {
    path: "/active-listings/:language?",
    component: lazy(() => import("../../view/pages/listings/active")),
    layout: "HorizontalLayout",
    private: true,
  },
  {
    path: "/unsold-listings/:language?",
    component: lazy(() => import("../../view/pages/listings/unsold")),
    layout: "HorizontalLayout",
    private: true,
  },
  {
    path: "/drafts-listings/:language?",
    component: lazy(() => import("../../view/pages/listings/drafts")),
    layout: "HorizontalLayout",
    private: true,
  },
  {
    path: "/scheduled-listings/:language?",
    component: lazy(() => import("../../view/pages/listings/scheduled")),
    layout: "HorizontalLayout",
    private: true,
  },
  {
    path: "/ended-listings/:language?",
    component: lazy(() => import("../../view/pages/listings/ended")),
    layout: "HorizontalLayout",
    private: true,
  },
  {
    path: "/account-and-settings/:language?",
    component: lazy(() => import("../../view/pages/profile")),
    layout: "HorizontalLayout",
    private: true,
  },
  {
    path: "/pages/profile/verification/:language?",
    component: lazy(() => import("../../view/pages/profile")),
    layout: "HorizontalLayout",
    private: true,
  },
  {
    path: "/pages/profile/withdrawal/:language?",
    component: lazy(() => import("../../view/pages/profile")),
    layout: "HorizontalLayout",
    private: true,
  },
  {
    path: "/pages/profile/notifications/:language?",
    component: lazy(() => import("../../view/pages/profile")),
    layout: "HorizontalLayout",
    private: true,
  },
  {
    path: "/pages/profile/activity/:language?",
    component: lazy(() => import("../../view/pages/profile")),
    layout: "HorizontalLayout",
    private: true,
  },
  {
    path: "/pages/profile/security/:language?",
    component: lazy(() => import("../../view/pages/profile")),
    layout: "HorizontalLayout",
    private: true,
  },
  {
    path: "/pages/profile/password-change/:language?",
    component: lazy(() => import("../../view/pages/profile")),
    layout: "HorizontalLayout",
    private: true,
  },
  {
    path: "/pages/profile/connect-with-social/:language?",
    component: lazy(() => import("../../view/pages/profile")),
    layout: "HorizontalLayout",
    private: true,
  },

  {
    path: "/pages/blank-page",
    component: lazy(() => import("../../view/pages/blank")),
    layout: "HorizontalLayout",
    private: true,
  },
  {
    path: "/pages/error-page",
    component: lazy(() => import("../../view/pages/error")),
    layout: "FullLayout",
    private: false,
  },
  {
    path: "/support/:language?",
    component: lazy(() => import("../../view/pages/support/index")),
    layout: "HorizontalLayout",
    private: true,
  },
  {
    path: "/pages/:slug",
    component: lazy(() => import("../../view/pages/navigation/index")),
    layout: "HorizontalLayout",
    private: false,
  },
];

export default PagesRoutes;
