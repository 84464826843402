import * as actionTypes from "./ticketTypes";

export const updateTicketsData = (value) => {
  return {
    type: actionTypes.UPDATE_TICKET_DATA,
    payload: value,
  };
};

export const isTicketLoading = (value) => {
  return {
    type: actionTypes.IS_TICKET_LOADING,
    payload: value,
  };
};
