import { Card, Col, Divider, Row, Typography } from 'antd'
import React from 'react'
const { Title, Text } = Typography;
import './ProductStatus.css'
const ProductStatus = () => {
    const productStatus = [{
        id: 1,
        status: 'Draft',
        value: '1,440'
    },
    {
        id: 2,
        status: 'Fixed Price',
        value: '15,000'
    },
    {
        id: 3,
        status: 'Auction',
        value: '2,400'
    },
    {
        id: 4,
        status: 'On Sale',
        value: '2,400'
    }
    ]
    return (
    <Card 
    className='productstatuscard'
    // style={{height:'40vh'}}
    // style={{marginTop:'12px'}}
    >
        <Title level={4}>Product Status</Title>
        <Divider />
        {productStatus.map((productStatus) => {
            return (
                <Row key={productStatus.id} gutter={[32, 32]} style={{ justifyContent: 'space-between', width: '97%', marginTop:'17px' }}>
                    <Col style={{ color: '#475467' }}>
                        {productStatus.status}
                    </Col>
                    <Col >
                        <Text strong>  {productStatus.value}</Text>
                    </Col>
                </Row>
            )
        })}
 </Card>
 )
}

export default ProductStatus
