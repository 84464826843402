import axios from 'axios';
import { BASE_URL } from '../configs/apiConfig';

// const token = localStorage?.getItem("token") && JSON.parse(localStorage?.getItem("token"));

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

async function makeRequest(endpoint, method, params, data) {
   const Localtoken = localStorage?.getItem("token");
try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Localtoken}`
    };
    // Check if the endpoint is for user registration, and if so, do not include the Authorization header
    if (endpoint !== '/auth/signup') {
      headers.Authorization = `Bearer ${Localtoken}`;
    }
   if (method == 'GET') {
      const response = await axiosInstance.request({
        url: endpoint,
        method,
        params,
        // params: params !== undefined && params,
        headers,
      });
      return response.data;
    } else {
      const response = await axiosInstance.request({
        url: endpoint,
        method,
        params,
        // params: params !== undefined && params,
        data,

        // data: method !== "GET" && data,
        headers,
      });
      return response.data;
    }
  } catch (err) {
    console.log("%c err", "background: red; color: white", err)
    throw err;
  }
}

export default makeRequest;
