import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Space } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdOutlineArrowDropDown } from 'react-icons/md';
import { useHistory, useLocation } from 'react-router-dom';

const LanguageTranslation = () => {
  const { t, i18n } = useTranslation();
  const [llanguage, setLanguage] = useState('ar')
  const handleChangeLng = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("lng", lng);
  }
const location =  useLocation();
const history =  useHistory()
  const language = localStorage.getItem("lng");
  if(language != null){
  if(language != llanguage){
     setLanguage(language)
     if(language != 'en'){
     history.push(location.pathname + '/?language=' + language)
     }else{
    history.push(location.pathname)
     }
   }
  }
  const menu = (
    <Menu>
      <Menu.Item onClick={() => handleChangeLng("en")}>English - EN</Menu.Item>
      <Menu.Item onClick={() => handleChangeLng("ar")}>العربية - AR</Menu.Item>
    </Menu>
  );
  
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  return (
    <Dropdown overlay={menu}>
      
      <a style={{display:'flex', alignItems: 'center',  width: '80px',
            height: '40px', 
            // backgroundColor: isHovering ? 'green' : 'blue',
            // color: isHovering ? 'white' : 'red',
            borderStyle: isHovering && 'solid', borderColor: isHovering && '#636e72'
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}> 
        {/* <img src="https://th.bing.com/th/id/OIP.ni1r3fZ9a92A2Hm_HN4XSQHaDt?w=303&h=175&c=7&r=0&o=5&dpr=1.1&pid=1.7" height={20} width={40} /> */}
          {language === 'en'? (
          <p style={{textAlign: 'center', marginTop:'14px', marginLeft:'3px', fontWeight: "bold"}}>EN</p>
        ) : (
          <p style={{textAlign: 'center', marginTop:'14px', marginLeft:'3px', fontWeight:'600'}}>AR</p>
        )}
        
        {/* <DownOutlined style={{ fontSize: '12px', marginLeft: '2.6px' }} /> */}
        <MdOutlineArrowDropDown style={{ fontSize: '24px', marginLeft:'-3px' }} />
      </a>
    </Dropdown>
  );
}
export default LanguageTranslation;