import { Card, Col, Row, Table, Tag, Typography } from 'antd';
import classNames from 'classnames';
import ResizeObserver from 'rc-resize-observer';
import React, { useEffect, useRef, useState } from 'react';
import { VariableSizeGrid as Grid } from 'react-window';
// Import debounce from lodash
import debounce from 'lodash/debounce';

const { Text } = Typography
const VirtualTable = (props) => {
  console.log("%c VirtualTable Component is calling", "background: black; color: white")

  const { columns, scroll } = props;
  const [tableWidth, setTableWidth] = useState(0);
  const widthColumnCount = columns.filter(({ width }) => !width).length;
  const mergedColumns = columns.map((column) => {
    if (column.width) {
      return column;
    }
    return {
      ...column,
      width: Math.floor(tableWidth / widthColumnCount),
    };
  });
  const gridRef = useRef();
  const [connectObject] = useState(() => {
    const obj = {};
    Object.defineProperty(obj, 'scrollLeft', {
      get: () => {
        if (gridRef.current) {
          return gridRef.current?.state?.scrollLeft;
        }
        return null;
      },
      set: (scrollLeft) => {
        if (gridRef.current) {
          gridRef.current.scrollTo({
            scrollLeft,
          });
        }
      },
    });
    return obj;
  });
  const resetVirtualGrid = () => {
    gridRef.current?.resetAfterIndices({
      columnIndex: 0,
      shouldForceUpdate: true,
    });
  };
  useEffect(() => resetVirtualGrid, [tableWidth]);
  const renderVirtualList = (rawData, { scrollbarSize, ref, onScroll }) => {
    console.log("%c renderirtualList is calling", "background: black; color: white")
    ref.current = connectObject;
    const totalHeight = rawData.length * 54;
    return (
      <Grid
        ref={gridRef}
        className="virtual-grid"
        columnCount={mergedColumns.length}
        columnWidth={(index) => {
          const { width } = mergedColumns[index];
          return totalHeight > scroll.y && index === mergedColumns.length - 1
            ? width - scrollbarSize - 1
            : width;
        }}
        height={scroll.y}
        rowCount={rawData.length}
        rowHeight={() => 54}
        width={tableWidth}
        onScroll={({ scrollLeft }) => {
          onScroll({
            scrollLeft,
          });
        }}
      >
        {({ columnIndex, rowIndex, style }) => (
          <div
            className={classNames('virtual-table-cell', {
              'virtual-table-cell-last': columnIndex === mergedColumns.length - 1,
            })}
            style={style}
          >
            {rawData[rowIndex][mergedColumns[columnIndex].dataIndex]}
          </div>
        )}
      </Grid>
    );
  };
  // const [tableWidth, setTableWidth] = useState(window.innerWidth);

  // const handleWindowResize = () => {
  //   setTableWidth(window.innerWidth);
  // };

  // useEffect(() => {
  //   window.addEventListener('resize', handleWindowResize);

  //   return () => {
  //     window.removeEventListener('resize', handleWindowResize);
  //   };
  // }, []);
   // Create a debounced function to handle the ResizeObserver callback
   const handleResize = debounce((width) => {
    console.log("%c Resize Obsever Component is calling!", "background: black; color: white", width);
    setTableWidth(width);
  }, 300); // Adjust the debounce delay as needed

  return (
    <ResizeObserver
      onResize={({ width }) => {
        console.log("%c Resize Obsever Component is calling!", "background: purple; color: white", width)
        handleResize(width);
        // setTableWidth(width);
      }}>
      <Table
        {...props}
        className="virtual-table"
        columns={mergedColumns}
        pagination={false}
        components={{
          body: renderVirtualList,
        }}
      />
     </ResizeObserver> 
  );
};

const columns = [
  {
    title: 'Order id',
    dataIndex: 'order_id',
    width: 100,
    key: 'order_id',
    render: (text) => {
      console.log("text data", text);
      return <span> ${text}</span>;
    },
    // render: (_,text) =>{ return( <p key={text}>#{text}</p>)},
  },
  {
    title: 'Product title',
    dataIndex: 'product__title',
    width: 150,
    // width: '20px',
    // key: 'product__title',
    render: (_, text) => (
      <div style={{ background: 'purple', color: 'white', width: '100px' }}>
        <img src={text.product_image} />
        <p>{text.product__title}</p>
      </div>
    )
    // }
  },
  {
    title: 'Date',
    dataIndex: 'date',
    width: 120
    // key: 'date',
  },
  {
    title: 'Price',
    // key: 'price',
    dataIndex: 'price',
    width: 100,
    render: (_, text) => {
      console.log("%c text data", "background: purple; color: white", text)
      return (
        <>
          {/* {tags.map((tag) => {
          let color = tag.length > 5 ? 'geekblue' : 'green';
          if (tag === 'loser') {
            color = 'volcano';
          }
          return (
            <Tag color={color} key={tag}>
              {tag.toUpperCase()}
            </Tag>
          );
        })} */}
        </>
      )
    },
  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    width: 100,
    render: (status) => (
        <Tag style={status == 'Shipped' ? {background:'hsla(144, 83%, 96%, 1)',color:'hsla(155, 96%, 24%, 1)', border:'none', fontSize:'12px', fontWeight:'600', borderRadius:'50px', padding:'5px 14px'} :  status === 'Denied' ? {background:'hsla(4, 88%, 97%, 1)',color:'hsla(4, 76%, 40%, 1)', border:'none',fontSize:'12px', fontWeight:'600', borderRadius:'50px', padding:'5px 14px'} : {background:'hsla(220, 22%, 96%, 1)',color:'hsla(217, 23%, 27%, 1)', border:'none',fontSize:'12px', fontWeight:'600', borderRadius:'50px', padding:'5px 14px'}} key={status}>
        {status}
      </Tag>
    )
      ,
  },
];
const data = [
  {
    key: '1',
    order_id: 3066,
    product__title: 'Cologo',
    product_image: './assets/Avatar (7).png',
    date: '22 Jan 2022',
    price: 2509.256,
    status: 'Processing'
  },
  {
    key: '2',
    order_id: 5056,
    product__title: 'Cologo',
    product_image: './assets/Avatar (8).png',
    date: '22 Jan 2022',
    price: 2509.256,
    status: 'Processing'

  },
  {
    key: '3',
    order_id: 3076,
    product__title: 'Cologo',
    product_image: '././assets/Avatar (9).png',
    date: '22 Jan 2022',
    price: 2509.256,
  }, {
    key: '4',
    order_id: 4066,
    product__title: 'Cologo',
    product_image: '././assets/Avatar (10).png',
    date: '22 Jan 2022',
    price: 2509.256,
    status: 'Shipped'

  },
  {
    key: '5',
    order_id: 2066,
    product__title: 'Cologo',
    product_image: '././assets/Avatar (11).png',
    date: '22 Jan 2022',
    price: 2509.256,
    status: 'Processing'

  },
  {
    key: '6',
    order_id: 3066,
    product__title: 'Cologo',
    product_image: '././assets/Avatar (7).png',
    date: '22 Jan 2022',
    price: 2509.256,
    status: 'Processing'

  },
  {
    key: '7',
    order_id: 3066,
    product__title: 'Cologo',
    product_image: '././assets/Avatar (8).png',
    date: '22 Jan 2022',
    price: 2509.256,
  },
  {
    key: '8',
    order_id: 3066,
    product__title: 'Cologo',
    product_image: '././assets/Avatar (9).png',
    date: '22 Jan 2022',
    price: 2509.256,
    status: 'Shipped'

  }, {
    key: '9',
    order_id: 3066,
    product__title: 'Cologo',
    product_image: '././assets/Avatar (10).png',
    date: '22 Jan 2022',
    price: 2509.256,
    status: 'Processing'

  },
  {
    key: '10',
    order_id: 3066,
    product__title: 'Cologo',
    product_image: '././assets/Avatar (11).png',
    date: '22 Jan 2022',
    price: 2509.256,
    status: 'Shipped'

  }
];
const LatestOrder = () => {
  console.log("%c LatestOrder Component is calling", "background: black; color: white")

  return (
    <Card>
      <Row>

        <Col span={24} style={{ marginBottom: '15px' }}>

          <Text style={{ color: 'hsla(221, 43%, 11%, 1)', marginBottom: '35px', fontSize: '15.4791px', fontWeight: '600' }}>Latest Order</Text>
        </Col>
        <Col xs={0} xl={24} className='latestordertable'>

          <VirtualTable
            columns={columns}
            dataSource={data}
            scroll={{
              y: 210,
            }}
          />
        </Col>
        <Col xs={0} md={24} xl={0} className='latestordertable'>

          <VirtualTable
            columns={columns}
            dataSource={data}
            scroll={{
              y: 220,
            }}
          />
        </Col>
        <Col xs={24} sm={24} md={0} xl={0} className='latestordertable'>

          <VirtualTable
            columns={columns}
            dataSource={data}
            scroll={{
              y: 270,
              x: 'max-content'
            }}
          />
        </Col>
      </Row>
    </Card>
  )
};
export default LatestOrder;
{/* <Table columns={columns} dataSource={data} /> */ }
