import axios, { BASE_URL } from "../configs/apiConfig";
import makeRequest from "./makeRequest";
// import { resolve } from './resolve.js';

export async function login() {
  return await axios.post("/auth/login").then((res) => res.data);
}
export async function loginCustomHeader(token) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`${BASE_URL}/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const jsonResponse = await response.json();
      resolve(jsonResponse);
    } catch (err) {
      reject(err);
    }
  });
}

// export async function signup(email) {
//   return await axios.post("/auth/signup", { email }).then((res) => res.data);
// }
export async function signup(body) {
  const jsonResponse = await makeRequest('/auth/signup', 'POST', {}, body);
  return jsonResponse;
  // return await axios.post("/auth/signup", body).then((res) => res.data);
}
export async function updateProfile(body) {
// const localToken = JSON.parse(localStorage?.getItem("token"));
const localToken = localStorage?.getItem("token");

return new Promise(async (resolve, reject) => {
  try {
    const response = await fetch(`${BASE_URL}/user`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localToken}`,
      },
      body: JSON.stringify(body), // Pass the 'body' here as a JSON string
     
    });
    const jsonResponse = await response.json();
    resolve(jsonResponse);
  } catch (err) {
    reject(err);
  }
});
  // return await axios.patch("/user", body).then((res) => res.data);
}
export async function allUsers(params) {
  return await axios.get("/user/", { params }).then((res) => res.data);
}
export async function sellerOnBoard(body) {
const localToken = localStorage?.getItem("token");
return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`${BASE_URL}/seller`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localToken}`,
        },
        body: JSON.stringify(body), // Pass the 'body' here as a JSON string
     
      });
      const jsonResponse = await response.json();
      resolve(jsonResponse);
    } catch (err) {
      reject(err);
    }
  });
  // const jsonResponse = await makeRequest('/seller', 'POST', {}, body);
  // console.log("%c jsonResponse::---", "background: purple; color: white", jsonResponse);
  // return jsonResponse;
  // return await axios.post("/seller", body).then((res) => res.data);
}
export async function sellerOnBoardUpdate(body) {
 const localToken = localStorage?.getItem("token");

  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`${BASE_URL}/seller`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localToken}`,
        },
        body: JSON.stringify(body),
      });
       const jsonResponse = await response.json();
       resolve(jsonResponse);
    } catch (err) {
      reject(err);
    }
  });
}
export async function getSellerById(id) {
  return await axios.get(`/seller/${id}`).then((res) => res.data);
}
export async function getSellerOwn(authToken) {
 // return await axios.get(`/seller/get/own`, token).then((res) => res.data);
// const localToken = JSON.parse(localStorage?.getItem("token"));
const localToken = localStorage?.getItem("token");
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`${BASE_URL}/seller/get/own`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      });
      const jsonResponse = await response.json();
      resolve(jsonResponse);
    } catch (err) {
      reject(err);
    }
  });
}
export async function getOwn() {
  return new Promise(async (resolve, reject) => {
    try {
// const localToken = JSON.parse(localStorage?.getItem("token"));
const localToken = localStorage?.getItem("token");
      const response = await fetch(`${BASE_URL}/seller/get/own`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localToken}`,
        },
      });
       const jsonResponse = await response.json();
      resolve(jsonResponse);
      return jsonResponse;
    } catch (err) {
      reject(err);
    }
  });
  // const jsonResponse = await makeRequest('/seller/get/own', 'GET', {}, {});
  // console.log("%c Seller own", "background: purple; color: white", jsonResponse);
  // return jsonResponse;
  // return await axios.get(`/seller/get/own`).then((res) => res.data);
}

export async function getWithdrawStatics() {
  return await axios.get(`/withdraw/pending`).then((res) => res.data);
}

export async function createTicket(body) {
  return await axios.post(`/ticket`, body).then((res) => res.data);
}

export async function getTickets(params) {
  // return await axios.get(`/ticket/seller/`, { params }).then((res) => res.data);
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${localToken}`,
  //     },})
  //   // .get(`/ticket/`, { params })

  //   .then((res) => res.data);
  
  const jsonResponse = await makeRequest('ticket/seller/', 'GET', params);
  return jsonResponse;
    // return new Promise(async (resolve, reject) => {
    //   try {
    //     const response = await fetch(`${BASE_URL}/ticket/seller/`, {
    //       method: "GET",
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${localToken}`,
    //       },
    //     });
    //     console.log("response", response)
    //     const jsonResponse = await response.json();
    //     console.log("%c jsonRresponse", "background: purple; color: white",jsonResponse)
  
    //     resolve(jsonResponse);
    //   } catch (err) {
    //     reject(err);
    //   }
    // });
    
}
export async function getAllTickets(params) {
  return await axios
    .get(`/ticket/`, { params })
    // .get(`/ticket/`, { params })

    .then((res) => res.data);
}
export async function getTicket(id) {
  return await axios
    .get(`/ticket/seller/${id}`)
    // .get(`/ticket/`, { params })

    .then((res) => res.data);
}
export async function getTicketById(params) {
  return await axios
    .get(`/ticket/buyer/`, { params })
    // .get(`/ticket/`, { params })

    .then((res) => res.data);
}

export async function getCategoryMenu() {
  return await axios.get(`/category/menu`).then((res) => res.data);
}

export async function getBrand() {
  return await axios.get(`/brand`).then((res) => res.data);
}

export async function getAttribute() {
  return await axios.get(`/attribute`).then((res) => res.data);
}

export async function getWithdrawRequest() {
  return await axios.post(`/withdraw/request`).then((res) => res.data);
}

export async function getWithdrawList(page = 1, limit = 10000) {
  return await axios
    .get(`/withdraw/seller?page=${page}&limit=${limit}`)
    .then((res) => res.data);
}

export async function getProductSeller(page = 1, limit = 10000) {
  return await axios
    .get(`/product/seller?page=${page}&limit=${limit}`)
    .then((res) => res.data);
}

export async function getProductReviewSeller(page = 1, limit = 10000, rating) {
  let query = {};
  if (rating) {
    query["ratingStar"] = rating;
  }
  return await axios
    .get(`/review/seller?page=${page}&limit=${limit}`, { params: query })
    .then((res) => res.data);
}
export async function getProductReviewById(id) {
  return await axios.get(`/review/seller/${id}`).then((res) => res?.data);
}
export async function editProduct(product) {
  return await axios.patch(`/product`, product).then((res) => res.data);
}

export async function updateOrderStatus(orderId, productId, status) {
  return await axios
    .post(`/order/${orderId}/product/${productId}/status/${status}`)
    .then((res) => res.data);
}

export async function addReplyReview(data) {
  return await axios.post(`/review/seller/reply`, data).then((res) => res.data);
}

export async function createProduct(product) {
  return await axios.post(`/product`, product).then((res) => res.data);
}

export async function getDashboardCount() {
  // return await axios.get(`/order/dashboard`).then((res) => res.data);
  return new Promise(async (resolve, reject) => {
    try {
// const localToken = JSON.parse(localStorage?.getItem("token"));
const localToken = localStorage?.getItem("token");
      const response = await fetch(`${BASE_URL}/order/dashboard`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localToken}`,
        },
      });
      const jsonResponse = await response.json();
      resolve(jsonResponse);
    } catch (err) {
      reject(err);
    }
  });
}

export async function getCoupons(page = 1, limit = 1000) {
  return await axios
    .get(`/coupon`, { params: { page, limit } })
    .then((res) => res.data);
}

export async function getOnBoard() {
  return await axios.get(`/seller/onboard`).then((res) => res.data);
}

export async function getAllSeller({page, limit}){
  // return await axios.get(`/seller?page=${page}&limit=${limit}`).then((res) => res.data); 
  const jsonResponse = await makeRequest(`/seller?page=${page}&limit=${limit}`, 'GET', {}, {});
  return jsonResponse;
}
export async function updateOnBoard(body) {
  return await axios.post(`/seller/onboard`, body).then((res) => res.data);
}

export async function getOneOrderByPidandOId(orderId, productId) {
  return await axios
    .get(`/order/product?orderId=${orderId}&productId=${productId}`)
    .then((res) => res.data);
}

export async function getOrderSeller(limit = 5, status, date1, date2) {
  let query = {};
  if (status) {
    query["status"] = status;
  }
  if (date1 && date2) {
    query["date1"] = date1;
    query["date2"] = date2;
  }

  query["limit"] = limit;
  // return await axios.get(`/order/seller`, { params: query }).then((res) => res.data);
  return new Promise(async (resolve, reject) => {
const localToken = localStorage?.getItem("token");

    try {
      const response = await fetch(`${BASE_URL}/order/seller`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localToken}`,
        },
      });
       const jsonResponse = await response.json();
      resolve(jsonResponse);
    } catch (err) {
      reject(err);
    }
  });
     // .get(`/order/seller`, { params: query })
}
// export async function getOrderSeller(token) {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const response = await fetch(`${BASE_URL}/order/seller`, {
//         method: "GET",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       const jsonResponse = await response.json();
//       resolve(jsonResponse);
//     } catch (err) {
//       reject(err);
//     }
//   });
// }
export async function getAllOrders(page, limit) {
  return await axios
    .get(`/order?page=${page}&limit=${limit}`)
    .then((res) => res.data);
}

export async function getOrdersBySeller(sellerId) {
  return await axios.get(`/order/seller/${sellerId}`).then((res) => res?.data);
}

export async function getProducts(q, categoryId, brandId) {
  let query = {};

  if (q) query["q"] = q;
  if (categoryId) query["categoryId"] = categoryId;
  if (brandId) query["brandId"] = brandId;

  return await axios.get(`/product`, { params: query }).then((res) => res.data);
}
export async function getSingleProduct(productId) {
  return await axios.get(`/product/${productId}/`).then((res) => res.data);
}
export async function getAllProduct(params) {
  return await axios.get(`/product/`, { params }).then((res) => res.data);
}

export async function getNavigation(params) {
  return await axios.get(`/navigation`, { params }).then((res) => res.data);
}

export async function getOnePage(slug) {
  return await axios.get(`/page/${slug}`).then((res) => res.data);
}
