import { Card, List, Typography } from 'antd';
const { Title } = Typography;

const SearchedAndUnavailableProducts = () => {
    const unavailableProducts = [
        { name: 'Product 1' },
        { name: 'Product 2' },
        { name: 'Product 3' },
        // Add more products as needed
      ];
    return (
    <div>
        <Card title="Products Searched by Buyers (Unavailable)">
      <List
        dataSource={unavailableProducts}
        renderItem={(product, index) => (
          <List.Item>
            <Title level={5}>{product.name}</Title>
          </List.Item>
        )}
      />
    </Card>
    </div>
  )
}

export default SearchedAndUnavailableProducts;
