import { SET_SELECTED_LANGUAGE } from './translationTypes';

const initialState = {
  selectedLanguage: 'en', // Default language
};

const translationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_LANGUAGE:
      return { ...state, selectedLanguage: action.payload };
    default:
      return state;
  }
};

export default translationReducer;
