import React, { useState } from "react";

import { Card, Row, Col, Divider, Empty } from "antd";
import Chart from "react-apexcharts";
import moment from "moment";
import { useSelector } from "react-redux";

export default function Announcements() {
  const ticketsRedux = useSelector((state) => state.tickets.ticketsData);
  return (
    <Card
      style={{ minHeight: "421px" }}
      className="hp-border-color-black-40 hp-card-6 hp-chart-text-color"
    >
      <Row>
        <Col className="hp-mb-16" span={24}>
          <Row justify="space-between">
            <Row align="bottom">
              <h4 className="hp-mr-8">Announcements</h4>
            </Row>
          </Row>
        </Col>

        {ticketsRedux.length ? (
          <Col span={24}>
            {ticketsRedux.map((item, index) => (
              <React.Fragment key={index}>
                <span className="hp-caption">
                  {item?.title} -{" "}
                  <small style={{ color: "#888" }}>
                    {moment(item?.created_at).format("D-MMM-YYYY")}
                  </small>
                </span>
                <div className="hp-font-size-base hp-color-black-10">
                  {item?.description}
                </div>
                <Divider className="hp-my-12" />
              </React.Fragment>
            ))}
          </Col>
        ) : (
          <Row justify="center" style={{ width: "100%" }}>
            <Col>
              <Empty />
            </Col>
          </Row>
        )}
      </Row>
    </Card>
  );
}
