import * as actionTypes from "./orderTypes";

const INITIAL_STATE = {
  dashboardCount: {
    shipping: { count: 0 },
    processing: { count: 0 },
    reviews: { count: 0 },
    productStatus: [],
    orderFilter: [],
    orderStatus: [
      {
        _id: "processing",
        count: 0,
      },
    ],
  },
  orderGetOne: {
    address: [
      {
        firstName: "loading ...",
        address1: "loading ...",
        phone1: "loading ...",
        phone2: "loading ...",
        email: "loading ...",
      },
    ],
  },
};

const customiseReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.ADD_DASHBOARD_COUNT:
       return {
        ...state,
        dashboardCount: action.payload,
      };
    case actionTypes.ADD_ONE_ORDER:
      return {
        ...state,
        orderGetOne: action.payload,
      };
    case actionTypes.ORDER_FILTER:
      return {
        ...state,
        orderFilter: action.payload
      } 
    default:
      return state;
  }
};

export default customiseReducer;
