export const ADD_ONBOARD = "ADD_ONBOARD";
export const INCREMENT_ONBOARD_COUNTER = "INCREMENT_ONBOARD_COUNTER";
export const DECREMENT_ONBOARD_COUNTER = "DECREMENT_ONBOARD_COUNTER";
export const RESET_ONBOARD_COUNTER = "RESET_ONBOARD_COUNTER";
export const UPDATE_TYPE = "UPDATE_TYPE";
export const ONBOARD_DOCUMENTS = "ONBOARD_DOCUMENTS";
// -------------- Buisness Types -----------------
export const ADD_ONBOARD_BUSSINESS = "ADD_ONBOARD_BUSSINESS";
export const INCREMENT_ONBOARD_COUNTER_BUSSINESS = "INCREMENT_ONBOARD_COUNTER_BUSSINESS";
export const DECREMENT_ONBOARD_COUNTER_BUSSINESS = "DECREMENT_ONBOARD_COUNTER_BUSSINESS";
export const RESET_ONBOARD_COUNTER_BUSSINESS = "RESET_ONBOARD_COUNTER_BUSSINESS";
export const UPDATE_TYPE_BUSSINESS = "UPDATE_TYPE_BUSSINESS";
export const ONBOARD_DOCUMENTS_BUSSINESS = "ONBOARD_DOCUMENTS_BUSSINESS";
