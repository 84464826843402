import React from 'react'
import { Space, Table, Tag } from 'antd';
import './withDrawStatuss.css'
const columns = [
  {
    title: 'Date',
    dataIndex: 'withdrawaldate',
    width: 120,
    // key: 'withdrawaldate',
    render: (text) => <a style={{color:'#475467'}}>{text}</a>,
  },
  {
    title: 'Account Title',
    dataIndex: 'accounttitle',
    width: 120,

    render: (text) => <a style={{color:'#101828',fontWeight: '600',fontSize: '14px'}}>{text}</a>
    // key: 'accounttitle',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    width: 100,

    // key: 'amount',
    render: (text) => <a style={{color:'#475467'}}>AED{text}</a>

  },
  {
    title: 'Status',
    // key: 'status',
    dataIndex: 'status',
    width: 100,

    render: (status) =>(
  // console.log("%c Status", "background: purple; color: white", status)
      // return(
       <>
             <Tag style={status == 'Approved' ? {background:'hsla(144, 83%, 96%, 1)',color:'hsla(155, 96%, 24%, 1)', border:'none', fontSize:'12px', fontWeight:'600', borderRadius:'50px', padding:'5px 14px'} :  status === 'Denied' ? {background:'hsla(4, 88%, 97%, 1)',color:'hsla(4, 76%, 40%, 1)', border:'none',fontSize:'12px', fontWeight:'600', borderRadius:'50px', padding:'5px 14px'} : {background:'hsla(220, 22%, 96%, 1)',color:'hsla(217, 23%, 27%, 1)', border:'none',fontSize:'12px', fontWeight:'600', borderRadius:'50px', padding:'5px 14px'}} key={status}>
              {status}
            </Tag>
           </>
    ),
    
}
  // {
  //   title: 'Action',
  //   key: 'action',
  //   render: (_, record) => (
  //     <Space size="middle">
  //       <a>Invite {record.name}</a>
  //       <a>Delete</a>
  //     </Space>
  //   ),
  // },
];
const data = [
  {
    key: '1',
    accounttitle: 'Zahir sahy',
    withdrawaldate: '22 Jan 2022',
    amount:'12.99',
   status: 'Approved',
  },
  {
    key: '2',
    accounttitle: 'Zahir sahy',
    withdrawaldate: '22 Jan 2022',
    amount: '12.99',
     status: 'Requested',
  },
  {
    key: '3',
    accounttitle: 'Zahir sahy',
    withdrawaldate: '22 Jan 2022',
    amount:'12.99',
    status: 'Denied',
  },
  {
    key: '4',
    accounttitle: 'Zahir sahy',
    withdrawaldate: '22 Jan 2022',
    amount:'12.99',
    status: 'Approved',
  },
  {
    key: '5',
    accounttitle: 'Zahir sahy',
    withdrawaldate: '22 Jan 2022',
    amount:'12.99',
    status: 'Approved',
  },
];
const WithdrawalHistory = () =>{
  return (
    <div id="scrollableDivv" >
       <h3 style={{ fontSize: '18px',
    lineHeight: '28px',
    color: 'rgb(16, 24, 40)', padding:'12px 0px 0px 12px'}}><strong>Withdrawal History</strong></h3>
    
 <Table  columns={columns} dataSource={data} pagination={false}  scroll={{
  y: 235,
}} />
 </div>
);
}
export default WithdrawalHistory;