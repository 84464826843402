import { combineReducers } from "redux";

import customiseReducer from "./customise/customiseReducer";
import productReducer from "./product/productReducer";
import orderReducer from "./order/orderReducer";
import onboardReducer from "./onBoard/onboardReducer4";
import ticketReducer from "./tickets/ticketReducer";
import profileReducer from "./profile/profileReducer";
import authReducer from "./auth/authReducer";
import footerReducer from "./footer/footerReducer";
import commentReducer from "./comments/commentReducer";
import onboardingTypeReducer from "./onboardingType/reducer";
import commentsReducer from "./commentss/commentsReducer";
import ratingFilterReducer from "./review/reviewReducer";
import onboardBusinessReducer from './onBoardingBussiness/businessReducer'
import translationReducer  from "./translation/translationReducer";

const rootReducer = combineReducers({
  customise: customiseReducer,
  products: productReducer,
  orders: orderReducer,
  onboardsBussiness: onboardBusinessReducer,
  onboards: onboardReducer,
  onboardingType: onboardingTypeReducer,
  tickets: ticketReducer,
  profile: profileReducer,
  auth: authReducer,
  footer: footerReducer,
  comments: commentReducer,
  comment: commentsReducer,
  ratingFilter: ratingFilterReducer,
 translation: translationReducer,

});
export default rootReducer;
