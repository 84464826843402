
const initialState = {
onboardingtype: "individual"
}
const onboardingTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ONBOARDING_TYPE":
      return {
        ...state,
        onboardingtype: action.payload,
      };
    default:
      return state;
  }
};
export default onboardingTypeReducer;