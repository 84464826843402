import React from "react";

import { Card, Row, Col } from "antd";
import { Ticket } from "react-iconly";
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom'
export default function AwaitingDispatch() {
  const useProductRedux = useSelector((state) => state.orders.dashboardCount);
    
  return (
    <Card className="hp-border-color-black-40" bodyStyle={{padding:'12px 12px 12px 24px'}}>
    <p style={{textAlign:'end', margin:'0'}}>
    <Link to='/orders'>
          see awaiting dispatch
           </Link>
        </p>
      <Row>
        <Col className="hp-mt-4">
          <h3 className="hp-mb-4">
            {useProductRedux !== undefined &&  useProductRedux.processing.count}
            <span className="hp-badge-text hp-ml-8 hp-text-color-warning-1">
              -1.4%
            </span>
          </h3>

          <p className="hp-p1-body hp-mb-0 hp-text-color-black-80 hp-text-color-dark-30">
            Awaiting Dispatch
          </p>
        </Col>
      </Row>
    </Card>
  );
}
