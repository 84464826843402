import { Card } from 'antd'
import React from 'react'

const Traffic = () => {
  return (
    <div>
      <Card>
        <h4>Traffic</h4>

        <h5 style={{fontWeight:'600'}}>Listing impressions</h5>
        <div style={{display:'flex'}}>
            <span style={{fontWeight:'600'}} className="text-muted">0 </span>
            <span style={{fontWeight:'600'}}>0.0%</span>
        </div>
        <div style={{width:'100%', height:'0.5px', background:'grey', margin:'16px 0px'}}></div>
        <h5 style={{fontWeight:'600'}}>Click-through rate</h5>
        <div >
            <span style={{fontWeight:'600'}} className="text-muted">0.0% </span>
        </div>
        <div style={{width:'100%', height:'0.5px', background:'grey', margin:'16px 0px'}}></div>
        <h5 style={{fontWeight:'600'}}>Listing page views</h5>
        <div style={{display:'flex'}}>
            <span style={{fontWeight:'600'}} className="text-muted">0 </span>
            <span style={{fontWeight:'600'}}>0.0%</span>
        </div>
        <div style={{width:'100%', height:'0.5px', background:'grey', margin:'16px 0px'}}></div>
        <h5 style={{fontWeight:'600'}}>Sales conversion rate</h5>
            <span style={{fontWeight:'600'}} className="text-muted">0 </span>

            <p style={{fontWeight:'500'}}>Data for Aug 2 – Sep 1 at 5:33am PDT. Percentage change relative to prior period. Performance statistics are rounded to the nearest tenth.</p>
          </Card>
    </div>
  )
}

export default Traffic
