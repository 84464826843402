/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
import S3 from "react-aws-s3";

const awsConfig = {
  ref: {
    Auth: {
      mandatorySignIn: true,
      region: "us-east-1",
      userPoolId: "us-east-1_q9xkM9JJh",
      identityPoolId: "us-east-1:040168cb-4ef7-44f7-b188-a0e51a6b818d",
      userPoolWebClientId: "7ra93jvdjcu30j24alr29jplv0",
      // storage: MemoryStorage,
      oauth: {
        domain: "http://localhost:3000",
        scope: ["email", "openid", "profile"],
        redirectSignIn: "http://localhost:3000/",
        redirectSignOut: "http://localhost:3000/",
        responseType: "token",
      },
      federationTarget: "COGNITO_USER_POOLS",
    },
  },
};
export default awsConfig;

// const config = {
//   bucketName: 'bkmatjar',
//   dirName: 'photos', /* optional */
//   region: 'eu-west-1',
//   accessKeyId: 'AKIAZEXMNPIJFGCQSLWO',
//   secretAccessKey: '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',
// }
const config = {
  bucketName: "admin-matjar",
  // bucketName: 'admin-matjar',
  dirName: "products" /* optional */,
  region: "me-south-1",
  s3Url: "https://admin-matjar.s3.me-south-1.amazonaws.com",
  accessKeyId: "AKIAWDQJ5ISA62TEIB5X",
  secretAccessKey: "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",
};

const ReactS3Client = new S3(config);

export { ReactS3Client };
