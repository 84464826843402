const themeConfig = {
    version: "1.0",
    theme: "light", // light - dark
    contentWidth: "full", // full - boxed
    sidebarCollapsed: false,
    sidebarCollapseButton: false,
    layout: "HorizontalLayout", // VerticalLayout - HorizontalLayout
    navigationFull: true,
    navigationBg: false,
    direction: "ltr", // ltr - rtl
}

export default themeConfig