
import React from 'react'
import { Typography, Card, Row, Col, Divider  } from 'antd';
const { Title, Text } = Typography;
import './Announcement.css'
const Announcement = () => {
    const announcement = [
        {
            id: 1,
            title: "Website Launched",
            description:"we are going to launch matjars soon. Stay tuned!",
            date: "14 jan,22"
        },
        {
            id: 2,
            title: "Website Launched",
            description:"we are going to launch matjars soon. Stay tuned!",
            date: "6 jan,22"
        },
        {
            id: 3,
            title: "Summer sale",
            description:"Summer sale will be here soon.",
            date: "4 jan,22"
        },
    ]
  return (
    <div>
      <Card
      className='announcementcard'
      //  style={{height:'40vh'}}
      >
      <Title level={4}>Announcement</Title>
      {announcement.map((announcement)=> {
        console.log("%c Announcement::-->>", "background: red; color: white", announcement)
        return(
            <Row key={announcement.id}>
            <Col>
            <Row gutter={[12,12]}>
                <Col>
                <Text style={{color:'#000000;', fontSize: '12.18px',fontWeight: 'bolder',lineHeight: '12px', letterSpacing: '0em',textAlign: 'left'
}} >{announcement.title}</Text>
                </Col>
                <Col>
                <Text style={{color: 'hsla(216, 18%, 34%, 1)'}}>{announcement.date}</Text></Col>
            </Row>
            <Row>
            <Col >
            <Text style={{color: 'hsla(216, 18%, 34%, 1)'}}>{announcement.description}</Text>
             </Col>
            </Row>
            </Col>
           
          </Row>
        )
      })}
     <Divider />
      </Card>
    </div>
  )
}

export default Announcement
