import { Card, Col, Row } from 'antd'
import React from 'react'
import { PiEye } from 'react-icons/pi'
const MostViewedProducts = () => {
  return (
    <div style={{background:'#fff'}}>
        <h3 style={{ fontSize: '18px',
    lineHeight: '28px',
    color: 'rgb(16, 24, 40)', padding:'12px 0px 0px 12px'}}><strong>Most Viewed Product</strong></h3>
      <Row gutter={[16,16]}>
         <Col span={12}>
            <Card>
                <Row>
                    <Col span={5}>
                    <img style={{maxWidth:'80%'}} src="https://th.bing.com/th/id/OIP.zH7COr_nhUKsuGTZ0CivhgHaHk?w=191&h=195&c=7&r=0&o=5&dpr=1.4&pid=1.7" />
                    </Col>
                    <Col span={15}>
                        <p style={{marginBottom:'0px', color:'#888888', fontWeight:'600'}}>Interior</p>
                        <p style={{ fontWeight:'600', marginBottom:'1px', fontSize:'15px'}}>Luxury Leather Recliner Sofa</p>
                        <p style={{color:'#787878'}}>$1,399</p>
                    </Col>
                    <Col span={4}>
                        <div style={{display:'flex', justifyContent:'space-evenly', alignItems:'center'}}>
                        <PiEye />
                       <p style={{fontWeight:'600',color:'#787878', margin:'20px 0'}}>2,300</p>
                       </div>
                    </Col>
                </Row>
            </Card>
         </Col>
         <Col span={12}>
         <Card>
                <Row>
                    <Col span={5}>
                    <img style={{maxWidth:'80%'}} src="https://th.bing.com/th/id/OIP.zH7COr_nhUKsuGTZ0CivhgHaHk?w=191&h=195&c=7&r=0&o=5&dpr=1.4&pid=1.7" />
                    </Col>
                    <Col span={15}>
                        <p style={{marginBottom:'0px', color:'#888888', fontWeight:'600'}}>Interior</p>
                        <p style={{ fontWeight:'600', marginBottom:'1px', fontSize:'15px'}}>Luxury Leather Recliner Sofa</p>
                        <p style={{color:'#787878'}}>$1,399</p>
                    </Col>
                    <Col span={4}>
                        <div style={{display:'flex', justifyContent:'space-evenly', alignItems:'center'}}>
                        <PiEye />
                       <p style={{fontWeight:'600',color:'#787878', margin:'20px 0'}}>2,300</p>
                       </div>
                    </Col>
                </Row>
            </Card>
         </Col>
         <Col span={12}>
            <Card>
                <Row>
                    <Col span={5}>
                    <img style={{maxWidth:'80%'}} src="https://th.bing.com/th/id/OIP.zH7COr_nhUKsuGTZ0CivhgHaHk?w=191&h=195&c=7&r=0&o=5&dpr=1.4&pid=1.7" />
                    </Col>
                    <Col span={15}>
                        <p style={{marginBottom:'0px', color:'#888888', fontWeight:'600'}}>Interior</p>
                        <p style={{ fontWeight:'600', marginBottom:'1px', fontSize:'15px'}}>Luxury Leather Recliner Sofa</p>
                        <p style={{color:'#787878'}}>$1,399</p>
                    </Col>
                    <Col span={4}>
                        <div style={{display:'flex', justifyContent:'space-evenly', alignItems:'center'}}>
                        <PiEye />
                       <p style={{fontWeight:'600',color:'#787878', margin:'20px 0'}}>2,300</p>
                       </div>
                    </Col>
                </Row>
            </Card>
         </Col>
         <Col span={12}>
         <Card>
                <Row>
                    <Col span={5}>
                    <img style={{maxWidth:'80%'}} src="https://th.bing.com/th/id/OIP.zH7COr_nhUKsuGTZ0CivhgHaHk?w=191&h=195&c=7&r=0&o=5&dpr=1.4&pid=1.7" />
                    </Col>
                    <Col span={15}>
                        <p style={{marginBottom:'0px', color:'#888888', fontWeight:'600'}}>Interior</p>
                        <p style={{ fontWeight:'600', marginBottom:'1px', fontSize:'15px'}}>Luxury Leather Recliner Sofa</p>
                        <p style={{color:'#787878'}}>$1,399</p>
                    </Col>
                    <Col span={4}>
                        <div style={{display:'flex', justifyContent:'space-evenly', alignItems:'center'}}>
                        <PiEye />
                       <p style={{fontWeight:'600',color:'#787878', margin:'20px 0'}}>2,300</p>
                       </div>
                    </Col>
                </Row>
            </Card>
         </Col>
      </Row>
    </div>
  )
}

export default MostViewedProducts
