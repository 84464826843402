
import { Button, Checkbox, Form, Input } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { buyerLogin } from '../../../redux/buyer/buyerAction';
const BuyerLogin = () => {
    const [state, setState] = React.useState({
      email:'',
        username: "",
        password: "",
      })
    const history = useHistory()
   const dispatch = useDispatch()
      function handleChange(evt) {
        const value = evt.target.value;
        const name = evt.target.name;
        setState({
          ...state,
          [name]: value
        });
      }
      const register = ()=> {
        dispatch(buyerLogin(state))
      }
  const onFinish = (values) => {
    console.log('Success:', values);
    history.push('/buyer')
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  return (
    // <h1>register</h1>
    <Form
      name="basic"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Username"
        name="username"
        value={state.username}
        rules={[
          {
            required: true,
            message: 'Please input your username!',
          },
        ]}
      >
         <Input
         name="username"
         value={state.username}
        onChange={handleChange} />
      </Form.Item>

      <Form.Item
        label="Password"
       
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
        ]}
      >
        <Input
         name="password"
         value={state.password}
        onChange={handleChange}/>
      </Form.Item>

      {/* <Form.Item
        name="remember"
        valuePropName="checked"
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Checkbox>Remember me</Checkbox>
      </Form.Item> */}

      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button type="primary" htmlType="submit" onClick={register}>
          login
        </Button>
      </Form.Item>
    </Form>
  );
};
export default BuyerLogin;