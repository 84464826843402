
import { CloseSquare, PaperPlus } from "react-iconly";
 const pages = [
    {
      header: "Dashboard",
      navLink: "/seller-dashboard/homepage",
    },
    {
      header: "Products",
      navLink: "/products",
    },
    {
      header: "Orders",
      navLink: "/orders",
    },
    {
      header: "Reviews",
      navLink: "/reviews",
    },
    {
      header: "Finance",
      navLink: "/finance",
    },
    {
      header: "Marketing",
      navLink: "/marketing",
    },
    {
      header: "Performance",
      navLink: "/performance",
    },
    // {
    //   header: "Store" ,
    //   navLink: "/store",
    // },
    {
      header: "Support",
      navLink: "/support",
    },
   
    //   {
    //     header: "Account & Settings",
    //     navLink: "/account-and-settings",
    //   },
  ];
 


export default pages;
