import { Card, Col, Row, Typography } from 'antd'
import React, { useState } from 'react'
const { Title,Text  } = Typography;
import ReactApexChart from 'react-apexcharts';
import './OrderStatus.css'
const OrderStatus = () => {
   const [state, setState] =useState({
        series: [44, 55, 13, 43, 22],
        options: {
          stroke: {
            width: 0
          },
         

            chart: {
            type: 'pie',
            offsetX: 25,
             },
          dataLabels: {
            enabled: false
        },
         colors: ['hsla(34, 96%, 44%, 1)','hsla(34, 76%, 55%, 1)','hsla(34, 76%, 66%, 1)','hsla(34, 76%, 77%, 1)','hsla(34, 76%, 89%, 1)'],
          labels: ['Completed 358', 'Pending 223', 'Shipped 113', 'Delivered 143', 'Withdrawal 34'],
          legend: {
            position:'bottom',
             floating: false,
            backgroundColor:'black',
            fontSize: '15px',
            fontFamily: 'Helvetica, Arial',
            fontWeight: 500,
            width: 400,
            // offsetX: 15,
            // offsetY: -10,
            color: 'red'
            },
//           responsive: [
//             {
//               breakpoint: 1600,
//               options: {
//                 // chart: {
//                 //   // height: 310
//                 //    height: 670,
//                 //    },
//                 // legend: {
//                 //   position: 'right',
//                 //   floating: false,
//                 //   backgroundColor:'black',
//                 //   fontSize: '14px',
//                 //   fontFamily: 'Helvetica, Arial',
//                 //   fontWeight: 200,
//                 //    offsetY: 10
//                 // },
//                 chart: {
//                   width: 420,
//                   height:400,
//                   offsetX: -35,
//                   offsetY: 10
//                 },
//                 legend: {
//                   position: 'right',
//                   floating: false,
//                   backgroundColor:'black',
//                   fontSize: '14px',
//                   fontFamily: 'Helvetica, Arial',
//                   fontWeight: 200,
                  
//                   offsetX: 9,
//                   offsetY: -10
//                   // right: '25px'
//                 },
//             },
//           },
//           {
//             breakpoint: 1500,
//             options: {
//               legend: {
//                 position: 'bottom',
//                 floating: false,
//                 backgroundColor:'black',
//                 fontSize: '14px',
//                 fontFamily: 'Helvetica, Arial',
//                 fontWeight: 200,
//                 height: 50,
//                 // width: 340,
//                 // offsetX: 15,
//                 offsetY: 10
//                 // right: '25px'
//               },
//           },
//         },
//           {
//             breakpoint: 1496,
//             options: {
//               chart: {
//                 width: 376,
//                 height: 375,
//                 offsetX: -49
//                },
         
//               legend: {
//                 position: 'bottom',
//                 floating: false,
//                 fontSize: '14px',
//                 fontFamily: 'Helvetica, Arial',
//                 fontWeight: 200,
//                 // width: 290,
//                 //  offsetY: -15
//                 },
                
//           },
//         },
//             {
//               breakpoint: 1356,
//               options: {
//                 chart: {
//                   width: 434,
//                   height: 370,
//                   offsetX: -89,
//                   // offsetY: 20,
                  
//                },
           
//                 legend: {
//                   position: 'bottom',
//                   floating: false,
//                   fontSize: '16px',
//                   fontFamily: 'Helvetica, Arial',
//                   fontWeight: 500,
//                   width: 280,
//                   offsetX: 49,
//                   //  offsetY: -15
//                   },
//                   },
//           },
//         {
//           breakpoint: 1265,
//           options: {
//             chart: {
//               width: 374,
//               height: 350,
//                offsetX: -74,
//               // offsetY: -1,
//             },
//             legend: {
//               position: 'bottom',
//               floating: false,
//               backgroundColor: 'black',
//               fontSize: '12px',
//               fontFamily: 'Helvetica, Arial',
//               fontWeight: 200,
//               // offsetX: 15,
//               // offsetY: -5
//               },
//         },
//       },
//       // ----- 1200px
//       {
//         breakpoint: 1200,
//         options: {
//           chart: {
//             width: 385,
//             height:340,
//             // offsetX: -47,
//             // offsetY: -20
//             offsetX: -64,
//             offsetY: -1
//           },
//           legend: {
//             position: 'bottom',
//             floating: false,
//             backgroundColor:'black',
//             fontSize: '12px',
//             fontFamily: 'Helvetica, Arial',
//             fontWeight: 200,
//             // height:100,
//             offsetX: 15,
//             offsetY: -5,
//             // width: 160,
//             // height: 200
//             // offsetX: 12,
//             // offsetY: -5
//             // right: '25px'
//           },
//       },
//     },
//     // ---- 1075 px
//     {
//       breakpoint: 1075,
//       options: {
//         chart: {
//           width: 335,
//           height:330,
//           // offsetX: -47,
//           // offsetY: -20
//           offsetX: -64,
//           offsetY: -1
//         },
//         legend: {
//           position: 'bottom',
//           floating: false,
//           backgroundColor:'black',
//           fontSize: '12px',
//           fontFamily: 'Helvetica, Arial',
//           fontWeight: 200,
//           offsetX: 15,
//           offsetY: -5,
//           },
//     },
//   },
//   // ---- 995 px
//   {
//     breakpoint: 992,
//     // breakpoint: 776,
//     options: {
//       chart: {
//         width: 394,
//         height: 250,
//         // color:'red',
        
//         // offsetX: -47,
//         // offsetY: -20
//         offsetX: -28,
//         offsetY: 12
//       },
//       legend: {
//         position: 'right',
//         floating: false,
//         backgroundColor: 'black',
//         fontSize: '11px',
//         fontFamily: 'Helvetica, Arial',
//         fontWeight: 200,
//         // width: 120,
//         offsetX: 15,
//         offsetY: -5
//         // right: '25px'
//       },
//   },
// },
// {
//             breakpoint: 835,
//             options: {
//               chart: {
//                 // width: 574,
//                 // height:420,
//                 // width: 564,
//                 // height: 410,
//                 width: 354,
//                 height: 290,
//                 //  offsetX: -39,
//                 // offsetY: -1
//               },
//               legend: {
//                 position: 'right',
//                 floating: false,
//                 backgroundColor: 'black',
//                 fontSize: '12px',
//                 fontFamily: 'Helvetica, Arial',
//                 fontWeight: 200,
//                 // width: 120,
//                 offsetX: 15,
//                 offsetY: -5
//                 // right: '25px'
//               },
//           },
//         },
//         {
//           breakpoint: 790,
//           options: {
//             chart: {
//               width: 354,
//               height: 290,
//               },
//             legend: {
//               position: 'bottom',
//               floating: false,
//               backgroundColor: 'black',
//               // fontSize: '42px',
//               fontFamily: 'Helvetica, Arial',
//               fontWeight: 200,
//               // width: 120,
//               offsetX: -15,
//               offsetY: -5
//               // right: '25px'
//             },
//         },
//       },
//       {
//         breakpoint: 768,
//         // breakpoint: 776,
//         options: {
//           chart: {
//             width: 514,
//             height: 500,
//             // color:'red',
            
//             // offsetX: -47,
//             // offsetY: -20
//             offsetX: 52,
//             // offsetY: -1
//           },
//           legend: {
//             position: 'right',
//             floating: false,
//             backgroundColor: 'black',
//             fontSize: '14px',
//             fontFamily: 'Helvetica, Arial',
//             fontWeight: 200,
//             // width: '60%',
//             offsetX: 15,
//             offsetY: -5
//             // right: '25px'
//           },
//       },
//     },
//     {
//       breakpoint: 656,
//       // breakpoint: 776,
//       options: {
//         chart: {
//           width: 414,
//           height: 410,
//           // color:'red',
          
//           // offsetX: -47,
//           // offsetY: -20
//           offsetX: -22,
//           // offsetY: -1
//         },
//         legend: {
//           position: 'bottom',
//           floating: false,
//           backgroundColor: 'black',
//           fontSize: '11px',
//           fontFamily: 'Helvetica, Arial',
//           fontWeight: 200,
//           // width: '60%',
//           offsetX: 15,
//           offsetY: -5
//           // right: '25px'
//         },
//     },
//   },
  
//         ]
responsive: [
  {
    breakpoint: 1690,
    options: {
      chart: {
        width: 490,
        height: 380,
        offsetX: -45,
        offsetY: 10
      },
      legend: {
        position: 'bottom',
        floating: false,
        backgroundColor:'black',
        fontSize: '13px',
        fontFamily: 'Helvetica, Arial',
        fontWeight: 200,
        // width: 320,
       
        offsetX: 29,
        offsetY: 2
        // right: '25px'
      },
  },
},
  {
    breakpoint: 1492,
    options: {
      chart: {
        width: 490,
        height: 400,
        offsetX: -99,
        offsetY: 10
      },
      legend: {
        position: 'bottom',
        floating: false,
        backgroundColor:'black',
        fontSize: '14px',
        fontFamily: 'Helvetica, Arial',
        fontWeight: 200,
        width: 365,
       
        offsetX: 59,
        offsetY: 2
        // right: '25px'
      },
  },
  },
 
  {
    breakpoint: 1600,
    options: {
      chart: {
        width: 440,
        height: 380,
        offsetX: -45,
        offsetY: 10
      },
      legend: {
        position: 'bottom',
        floating: false,
        backgroundColor:'black',
        fontSize: '14px',
        fontFamily: 'Helvetica, Arial',
        fontWeight: 200,
        // width: 320,
       
        offsetX: 9,
        offsetY: 2
        // right: '25px'
      },
  },
},
{
  breakpoint: 1492,
  options: {
    chart: {
      width: 470,
      height: 390,
      offsetX: -85,
      offsetY: 10
    },
    legend: {
      position: 'bottom',
      floating: false,
      backgroundColor:'black',
      fontSize: '14px',
      fontFamily: 'Helvetica, Arial',
      fontWeight: 200,
      width: 345,
     
      offsetX: 59,
      offsetY: 2
      // right: '25px'
    },
},
},
{
  breakpoint: 1400,
  options: {
    chart: {
      width: 460,
      height: 380,
      offsetX: -85,
      offsetY: 10
    },
    legend: {
      position: 'bottom',
      floating: false,
      backgroundColor:'black',
      fontSize: '13px',
      fontFamily: 'Helvetica, Arial',
      fontWeight: 200,
      width: 350,
     
      offsetX: 33,
      offsetY: 2
      // right: '25px'
    },
},
},
  {
    breakpoint: 1356,
    options: {
      chart: {
        width: 460,
        height:390,
        offsetX: -85,
        offsetY: 10
      },
      legend: {
        position: 'bottom',
        floating: false,
        backgroundColor:'black',
        fontSize: '13px',
        fontFamily: 'Helvetica, Arial',
        fontWeight: 200,
        width: 346,
     
        offsetX: 49,
        // offsetY: -15
        // right: '25px'
      },
  },
},
{
  breakpoint: 1330,
  options: {
    chart: {
      width: 460,
      height:390,
      offsetX: -85,
      offsetY: 10
    },
    legend: {
      position: 'bottom',
      floating: false,
      backgroundColor:'black',
      fontSize: '12px',
      fontFamily: 'Helvetica, Arial',
      fontWeight: 200,
      width: 346,
   
      offsetX: 39,
      // offsetY: -15
      // right: '25px'
    },
},
},
{
  breakpoint: 1265,
  options: {
    chart: {
      width: 400,
      height:380,
      // offsetX: -47,
      // offsetY: -10,
      offsetX: -67,
      offsetY: 19,
      margin: {
        // bottom: '80%'
      }
    },
    legend: {
      position: 'bottom',
      floating: false,
      backgroundColor:'black',
      fontSize: '12.5px',
      fontFamily: 'Helvetica, Arial',
      fontWeight: 300,
      offsetX: 24,
      offsetY: -3,
      width: 310,
      margin: {
        bottom: '60%',
        
      }
      // height: 90
      // offsetX: 12,
      // offsetY: -5
      // right: '25px'
    },
},
},
{
  breakpoint: 1230,
  options: {
    chart: {
      width: 400,
      height:380,
      // offsetX: -47,
      // offsetY: -10,
      offsetX: -74,
      offsetY: 19,
      margin: {
        // bottom: '80%'
      }
    },
    legend: {
      position: 'bottom',
      floating: false,
      backgroundColor:'black',
      fontSize: '12.5px',
      fontFamily: 'Helvetica, Arial',
      fontWeight: 300,
      offsetX: 24,
      offsetY: -3,
      width: 310,
      margin: {
        bottom: '60%',
        
      }
      // height: 90
      // offsetX: 12,
      // offsetY: -5
      // right: '25px'
    },
},
},
// ----- 1200px
{
breakpoint: 1200,
options: {
  chart: {
    width: 395,
    height:350,
    // offsetX: -47,
    // offsetY: -20
    offsetX: -74,
    offsetY: 12
  },
  legend: {
    position: 'bottom',
    floating: false,
    backgroundColor:'black',
    fontSize: '14px',
    fontFamily: 'Helvetica, Arial',
    fontWeight: 200,
    offsetX: 22,
    // offsetY: -5,
    // width: 160,
    // height: 200
    // offsetX: 12,
    // offsetY: -5
    // right: '25px'
  },
},
},
// ---- 1075 px
{
breakpoint: 1075,
options: {
chart: {
  width: 335,
  height:330,
  // offsetX: -47,
  // offsetY: -20
  offsetX: -54,
  offsetY: 10

  // offsetY: -1
},
legend: {
  position: 'bottom',
  floating: false,
  backgroundColor:'black',
  fontSize: '13.5px',
  fontFamily: 'Helvetica, Arial',
  fontWeight: 200,
  offsetX: -3,
  // offsetY: -5,
  // width: 160,
  // height: 200
  // offsetX: 12,
  // offsetY: -5
  // right: '25px'
},
},
},
// ---- 995 px
{
breakpoint: 992,
// breakpoint: 776,
options: {
chart: {
width: 584,
height: 350,
// color:'red',

// offsetX: -47,
// offsetY: -20
offsetX: -32,
offsetY: 12
},
legend: {
position: 'right',
floating: false,
backgroundColor: 'black',
fontSize: '11px',
fontFamily: 'Helvetica, Arial',
fontWeight: 200,
// width: 120,
offsetX: 15,
offsetY: -5
// right: '25px'
},
},
},
// ---- 835px
{
  breakpoint: 835,
  // breakpoint: 776,
  options: {
    chart: {
      width: 544,
      height: 450,
      // color:'red',
      
      // offsetX: -47,
      // offsetY: -20
      offsetX: -32,
      // offsetY: -1
    },
    legend: {
      position: 'right',
      floating: false,
      backgroundColor: 'black',
      fontSize: '11px',
      fontFamily: 'Helvetica, Arial',
      fontWeight: 200,
      // width: 120,
      offsetX: 15,
      offsetY: -5
      // right: '25px'
    },
},
},
// {
//   breakpoint: 835,
//   // breakpoint: 776,
//   options: {
//     chart: {
//       width: 364,
//       height: 250,
//       // color:'red',
      
//       // offsetX: -47,
//       // offsetY: -20
//       offsetX: -32,
//       // offsetY: -1
//     },
//     legend: {
//       position: 'right',
//       floating: false,
//       backgroundColor: 'black',
//       fontSize: '11px',
//       fontFamily: 'Helvetica, Arial',
//       fontWeight: 200,
//       // width: 120,
//       offsetX: 15,
//       offsetY: -5
//       // right: '25px'
//     },
// },
// },
{
breakpoint: 790,
options: {
  chart: {
    width: 354,
    height: 290,
    // width: 200,
    // height: 100,
    // offsetX: -47,
    // offsetY: -20
    // offsetX: -69,
    // offsetY: -1
  },
  legend: {
    position: 'bottom',
    floating: false,
    backgroundColor: 'black',
    // fontSize: '42px',
    fontFamily: 'Helvetica, Arial',
    fontWeight: 200,
    // width: 120,
    offsetX: -15,
    offsetY: -5
    // right: '25px'
  },
},
},
// {
// breakpoint: 768,
// // breakpoint: 776,
// options: {
//   chart: {
//     width: 514,
//     height: 400,
//     // color:'red',
    
//     // offsetX: -47,
//     // offsetY: -20
//     offsetX: 52,
//     // offsetY: -1
//   },
//   legend: {
//     position: 'right',
//     floating: false,
//     backgroundColor: 'black',
//     fontSize: '14px',
//     fontFamily: 'Helvetica, Arial',
//     fontWeight: 200,
//     // width: '60%',
//     offsetX: 15,
//     offsetY: -5
//     // right: '25px'
//   },
// },
// },
{
  breakpoint: 768,
  // breakpoint: 776,
  options: {
    chart: {
      width: 674,
      height: 490,
      // color:'red',
      
      // offsetX: -47,
      // offsetY: -20
      offsetX: 52,
      offsetY: 20
    },
    legend: {
      position: 'right',
      floating: false,
      backgroundColor: 'black',
      fontSize: '15px',
      fontFamily: 'Helvetica, Arial',
      fontWeight: 200,
      // width: '60%',
      offsetX: 15,
      offsetY: -5
      // right: '25px'
    },
},
},
{
breakpoint: 656,
// breakpoint: 776,
options: {
  chart: {
    width: 620,
    height: 540,
    offsetX: 32,
  },
  legend: {
    position: 'right',
    floating: false,
    backgroundColor: 'black',
    fontSize: '12px',
    fontFamily: 'Helvetica, Arial',
    fontWeight: 200,
    offsetY: -5
   },
},
},
{
  breakpoint: 594,
  // breakpoint: 776,
  options: {
    chart: {
      width: 620,
      height: 540,
      offsetX: 2,
    },
    legend: {
      position: 'right',
      floating: false,
      backgroundColor: 'black',
      fontSize: '11px',
      fontFamily: 'Helvetica, Arial',
      fontWeight: 200,
      offsetY: -5
     },
  },
  },
{
  breakpoint: 552,
  // breakpoint: 776,
  options: {
    chart: {
      // width: 420,
      // height: 350,
      width: 620,
      height: 540,
      
      // color:'red',
      
      // offsetX: -47,
      // offsetY: -20
      offsetX: -32,
      // offsetY: -1
    },
    legend: {
      position: 'right',
      floating: false,
      backgroundColor: 'black',
      fontSize: '11px',
      fontFamily: 'Helvetica, Arial',
      fontWeight: 200,
      // width: '60%',
      // offsetX: 45,
      offsetY: -5
      // right: '25px'
    },
  },
  },
  {
    breakpoint: 484,
    // breakpoint: 776,
    options: {
      chart: {
        width: 420,
        height: 350,
        // width: 620,
        // height: 540,
        
        // color:'red',
        
        // offsetX: -47,
        // offsetY: -20
        offsetX: -62,
        // offsetY: -1
      },
      legend: {
        position: 'bottom',
        floating: false,
        backgroundColor: 'black',
        fontSize: '11px',
        fontFamily: 'Helvetica, Arial',
        fontWeight: 200,
        // width: '60%',
        offsetX: 35,
        // offsetY: -10
        // right: '25px'
      },
    },
    },
]
        },
        })

       return (
      <Card
      className='orderstatus'
    //   bodyStyle={ @media (max-width: 1268px)': {
    //     backgroundColor: 'blue',
    //     color: 'black'
    //   }}
    // }
    bodyStyle={{paddingBottom:'34px'}}
   >
      {/* <Title level={4}
      className='orderstatus' 
      // style={{fontFamily:'Chela One', cursive}}
      >Order Status</Title> */}
        <Text style={{fontWeight:'900', color:'hsla(221, 43%, 11%, 1)'}}>Order Status</Text>
   
      {/* <Row  gutter={{
        // xs: 8,
        // sm: 16,
        // md: 24,
        // lg: p,
        // xl:12
      }}> */}
        {/* <Col   
        //  style={{paddingLeft:'12px'}}
       className='orderstatuss'  > */}
        <ReactApexChart options={state.options} series={state.series} type="pie" 
           height={420}
           width={340}
           />
         {/* </Col>
      </Row> */}
      </Card>
    )
}

export default OrderStatus
