import i18next from "i18next";
import { initReactI18next } from "react-i18next";

i18next.use(initReactI18next).init({
    resources: {
        en: {
            translation: {
                hello: "hello",
                Dashboard: "Dasboard", 
                Products: "Products",
                Orders: "Orders",
                Reviews: "Reviews",
                Finance: "Finance",
                Support: "Support",
                Switch_to_buyer: "Switch to buyer",
            },
        },
        ar: {
            translation: {
                hello: "مرحبًا",
                Dashboard:"لوحه القياده",
                Products: "منتجات",
                Orders: "اوامر",
                Reviews: "ملاحظات",
                Finance: "الماليه",
                Support: "دعم",
                Switch_to_buyer : "التبديل إلى المشتري"
            },
        }
    },
    lng:  localStorage.getItem("lng") || "en",
})

export default i18next;