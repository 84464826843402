import * as actionTypes from "./profileTypes";

const INITIAL_STATE = {
  profileModal: false,
};

const profileReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.OPEN_PROFILE_MODAL:
      return {
        ...state,
        profileModal: true,
      };
    case actionTypes.CLOSE_PROFILE_MODAL:
      return {
        ...state,
        profileModal: false,
      };
    default:
      return state;
  }
};

export default profileReducer;
