import React from 'react'
import { Avatar, Card, Comment, Tooltip } from 'antd';
const Feedback = () => {
    function truncateText(text, wordCount) {
        const words = text.split(' ');
        if (words.length <= wordCount) {
            return text;
        } else {
            const truncatedText = words.slice(0, wordCount).join(' ');
            return truncatedText + '...';
        }
    }

    const originalText =
        'We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.';

    const truncatedText = truncateText(originalText, 50);

    return (
        <div>
            <Card>
                <h4>Feeback</h4>
                <Comment
                    author={<a>Han Solo</a>}
                    avatar={<Avatar src="https://th.bing.com/th/id/OIP.t21mk26_1Jp2APuMyRO4RgHaHa?w=202&h=202&c=7&r=0&o=5&dpr=1.4&pid=1.7" alt="Han Solo" />}
                    content={
                        <p>
                            {truncateText(originalText, 20)}
                        </p>
                    }
                    datetime={<span>8 hours ago</span>} />
           <Comment
                    author={<a>Han Solo</a>}
                    avatar={<Avatar src="https://th.bing.com/th/id/OIP.t21mk26_1Jp2APuMyRO4RgHaHa?w=202&h=202&c=7&r=0&o=5&dpr=1.4&pid=1.7" alt="Han Solo" />}
                    content={
                        <p>
                            {truncateText(originalText, 20)}
                        </p>
                    }
                    datetime={<span>8 hours ago</span>} />
           
           <Comment
                    author={<a>Han Solo</a>}
                    avatar={<Avatar src="https://th.bing.com/th/id/OIP.t21mk26_1Jp2APuMyRO4RgHaHa?w=202&h=202&c=7&r=0&o=5&dpr=1.4&pid=1.7" alt="Han Solo" />}
                    content={
                        <p>
                            {truncateText(originalText, 20)}
                        </p>
                    }
                    datetime={<span>8 hours ago</span>} />
           
            </Card>
        </div>
    )
}

export default Feedback
