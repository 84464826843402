import { Suspense, useEffect, useState } from "react";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { theme } from "../redux/customise/customiseActions";
import Cookies from "js-cookie";

// Router
import {
  BrowserRouter,
  Route,
 Switch,
  useHistory,
  Redirect
  // useHistory,
} from "react-router-dom";

// Routes
// import Routes  from "./routes";

// Layouts
import VerticalLayout from "../layout/VerticalLayout";
import HorizontalLayout from "../layout/HorizontalLayout";
import HorizontalLayoutPublic from "../layout/HorizontalLayoutPublic";
import FullLayout from "../layout/FullLayout";

// Components
// import HomePage from "../view/home";
import HomePage from "../view/home/dashboard/ecommerce";
import Error404 from "../view/pages/error";
import { Routes } from "./routes";
import SellerDashboard from "../view/pages/seller-dashboard/cards/HomePage";
import EmailModal from "../view/components/modals/EmailModal";
import { loginCustomHeader } from "../service/api";


export default function Router() {
  const [showModal, setShowModal] = useState(true);
  const [newEmail, setNewEmail] = useState(false);
   const [isStatus, setIsStatus] = useState(false);
  const [loginToken, setLoginToken] = useState([]);

  // Redux
  const customise = useSelector((state) => state.customise);
  const dispatch = useDispatch();

  // Location
  const location = useHistory();
  // Dark Mode
  useEffect(() => {
    document.querySelector("body").classList.add(customise.theme);
    dispatch(theme(customise.theme));
  }, []);

  // RTL
  useEffect(() => {
   if (customise.direction == "ltr") {
      document.querySelector("html").setAttribute("dir", "ltr");
    } else if (customise.direction == "rtl") {
      document.querySelector("html").setAttribute("dir", "rtl");
    }
  }, []);

// debugger
  const isAuthenticated = (_) => !!localStorage.getItem("token");
  // Default Layout
  const DefaultLayout = customise.layout; // FullLayout or VerticalLayout
  // All of the available layouts
  const Layouts = {
    VerticalLayout,
    HorizontalLayout,
    HorizontalLayoutPublic,
    FullLayout,
  };

  // Return Filtered Array of Routes & Paths
  const LayoutRoutesAndPaths = (layout) => {
    const LayoutRoutes = [];
    const LayoutPaths = [];
    if (Routes) {
      // Checks if Route layout or Default layout matches current layout
      Routes.filter(
        (route) =>
          route.layout === layout &&
          (route?.private == false ||
            (route?.private == true && isAuthenticated() == true)) &&
          (LayoutRoutes.push(route), LayoutPaths.push(route.path))
      );
    }

    return { LayoutRoutes, LayoutPaths };
  };

  // Return Route to Render
  const ResolveRoutes = () => {
    return Object.keys(Layouts).map((layout, index) => {
      const { LayoutRoutes, LayoutPaths } = LayoutRoutesAndPaths(layout);

      let LayoutTag;
      if (DefaultLayout == "HorizontalLayoutPublic") {
        if (layout == "HorizontalLayout") {
          LayoutTag = Layouts["HorizontalLayoutPublic"];
        } else {
          LayoutTag = Layouts[layout];
        }
      } else {
        LayoutTag = Layouts[layout];
      }
      return (
        <Route path={LayoutPaths} key={index}>
          <LayoutTag>
            <Switch>
              {LayoutRoutes.map((route) => {
                return (
                  <Route
                    key={route.path}
                    path={route.path}
                    exact={route.exact === true}
                    render={(props) => {
                      return (
                        <Suspense fallback={null}>
                          <route.component {...props} />
                        </Suspense>
                      );
                    }}
                  />
                );
              })}
            </Switch>
          </LayoutTag>
        </Route>
      );
    });
  };
  const dynamicSwitching = () => {
    if (Cookies.get("buyerToken") || localStorage.getItem("matjar_user_data")) {
      loginCustomHeader(JSON.parse(Cookies.get("buyerToken")) || loginToken.token)
        .then((res) => {
          if (!localStorage.getItem("matjar_user_data")) {
            // setLoggedIn(true);
            localStorage.setItem(
              "matjar_user_data",
              JSON.stringify({
                ...res.data,
                Email: JSON.parse(Cookies.get("buyerEmail")),
                token: JSON.parse(Cookies.get("buyerToken")) || loginToken.token,
              })
            );

            window.location.reload();
            // setTimeout(() => {
            // }, 1000);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      // window.location.reload();
    }
  };
  return (
    // <BrowserRouter>
    <>
    {Cookies.get("buyerToken") && Cookies.get("buyerModalStatus") == true && (
      <>
        <EmailModal
          isOpen={showModal}
          message={`Do you want to continue with this email ${JSON.parse(
            Cookies.get("buyerEmail")
          )} ?`}
          closeModal={() => {
            setShowModal(false);
            dynamicSwitching();
            setNewEmail(true);
            Cookies.set("buyerModalStatus", true);
            // setIsShow(false);
          }}
          newEmail={() => {
            setNewEmail(false);
            setShowModal(false);
            setIsStatus(true);
            Cookies.remove("Token");
            Cookies.remove("email");
            Cookies.remove("session");
            localStorage.removeItem("matjar_user_data");
            window.location.reload();
          }}
        />
      </>
    )}
      <Switch>
        {ResolveRoutes()}
        {/* Home Page */}
      
        {!isAuthenticated() && <Redirect to="/authentication/login" />}
        {isAuthenticated() && (
          <Route
            exact
            // path={"//:language?"}
            path={"/:language?"}
            render={() => {
              return DefaultLayout == "HorizontalLayoutPublic" ? (
                <Layouts.HorizontalLayoutPublic>
                  <SellerDashboard />
                  {/* <HomePage /> */}
                </Layouts.HorizontalLayoutPublic>
              ) : (
                <Layouts.HorizontalLayout>
                  <SellerDashboard />
                  {/* <HomePage /> */}
                </Layouts.HorizontalLayout>
              );
            }}
          />
        )}
        {/* NotFound */}
        <Route path="*">
          <Error404 />
        </Route>
      </Switch>
      </>
    // </BrowserRouter>
  );
}
