import * as actionTypes from './reviewType'
let INITIAL_STATE = {
    ratingFilter: []
}
 const ratingFilterReducer = (state=INITIAL_STATE, action)=> {
switch(action.type){
    case actionTypes.RATING_FILTER:
        return {
            ...state,
            ratingFilter: action.payload
        }
    default: 
      return state;
         
}
}
export default ratingFilterReducer