import {
  CloseSquare,
  PaperPlus,
  Home,
  Work,
  Scan,
  Bag2,
  Filter2,
  Discount,
  Category,
  Swap,
  Wallet,
  Buy,
  People,
  Paper,
  Discovery,
  Message,
  Bookmark,
  TwoUsers,
  Chat,
  Graph,
  Setting,
  Filter,
  Game,
} from "react-iconly";
const pages = [
  {
    header: "Dashboard",
  },
  {
    id: "Dashboard",
    title: "Dashboard",
    icon: <Home set="curved" className="remix-icon" />,
    navLink: "/seller-dashboard/homepage",
  },
  {
    header: "Pages",
  },
  {
    id: "Products",
    title: "Products",
    icon: <PaperPlus set="curved" className="remix-icon" />,
    navLink: "/products",
  },
  {
    id: "Orders",
    title: "Orders",
    icon: <Wallet set="curved" className="remix-icon" />,
    navLink: "/orders",
  },
  {
    id: "Reviews",
    title: "Reviews",
    icon: <Message set="curved" className="remix-icon" />,
    navLink: "/reviews",
  },
  {
    id: "Finance",
    title: "Finance",
    icon: <Wallet set="curved" className="remix-icon" />,
    navLink: "/finance",
  },
  // {
  //   header: "Store" ,
  //   navLink: "/store",
  // },
  {
    id: "Marketing",
    title: "Marketing",
    icon: <Wallet set="curved" className="remix-icon" />,
    navLink: "/marketing",
  },
  {
    id: "Support",
    title: "Support",
    icon: <PaperPlus set="curved" className="remix-icon" />,
    navLink: "/support",
  },
  // {
  //     id: "errors",
  //     title: "Error Pages",
  //     icon: <CloseSquare set="curved" className="remix-icon" />,
  //     children: [
  //         {
  //             id: "error-page",
  //             title: "404",
  //             navLink: "/pages/error-page",
  //         },
  //     ],
  // },
];

export default pages;
