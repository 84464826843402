import * as actionTypes from './commentsTypes';

const initialState = {
  commentss: []
}
const commentReducer = (state = initialState, action) => {
    switch(action.type){
    case actionTypes.COMMENTTT:
        return {
            ...state,
            commentss: [...state.commentss, action.payload]
            // ...state.comments,
        //     comments: [
        //         // ...state.comments,
        //          {
        //        comment: action.payload[0], 
        //       date:  action.payload[1]
        //     }
        // ],
            // date:  action.payload[1]
            // comments: [...state, action.payload]
        }
        case actionTypes.DELETE_COMMENT:
            // return {
            //     ...state,
            //     commentss: state.commentss.filter((item) => item._id !== action.payload)
            
            // }
            return {
                    ...state,
                 comments: state?.commentss?.filter((item) => item._id !== action.payload)
            }
            default:
            return state;
}
}
export default commentReducer