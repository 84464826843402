import React, { useState } from 'react'
import "./vectormap.css";
import { ComposableMap, Geographies, Geography, Marker } from "react-simple-maps"
import { Card, Col, Divider, Progress, Row, Tooltip, Typography } from 'antd';
import TopSellingRegion from './TopSellingRegion';
const { Text } = Typography;
const geoUrl ="https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json"

const markers = [
  { markerOffset: -15, name: "Dubai", coordinates: [18.1193, -16.4897] },
  { markerOffset: 25, name: "Abu Dhabi", coordinates: [47.8825, 15.7942] },
  { markerOffset: 25, name: "Sharjah", coordinates: [-70.6693, -33.4489] },
  { markerOffset: 25, name: "KPK", coordinates: [74.0721, 4.711] },
  { markerOffset: 25, name: "Ras Al Khaimah", coordinates: [64.0721, 42.711] },
  { markerOffset: 25, name: "Ajman", coordinates: [-30.0721, 4.711] },
  { markerOffset: 25, name: "Fujairah", coordinates: [34.0721, 4.711] },
  { markerOffset: 25, name: "Umm Al Quwain", coordinates: [-4.0721, -44.711] },
];
const colorr = '#fff'
const VectorMap = () => {
  const [topSellingRegion, setTopSellingRegion] = useState([

    {
      id: 1,
      product: "Dubai",
      percent: (115 / 500) * 100,
      color: 'hsla(33, 96%, 44%, 1)'
    },

    {
      id: 2,
      product: 'Abu Dhabi',
      percent: (250 / 500) * 100,
      color: 'hsla(33, 96%, 44%, 1)'
    },
    {
      id: 3,
      product: 'Sharjah',
      percent: (30 / 500) * 100,
      color: 'hsla(33, 96%, 44%, 1)'
    },
    {
      id: 4,
      product: "Ras Al Khaimah",
      percent: (85 / 500) * 100,
      color: 'hsla(33, 96%, 44%, 1)'
    },
    {
      id: 5,
      product: "Ajman",
      percent: (85 / 500) * 100,
      color: 'hsla(33, 96%, 44%, 1)'
    },
    {
      id: 6,
      product: "Fujairah",
      percent: (85 / 500) * 100,
      color: 'hsla(33, 96%, 44%, 1)'
    },
    {
      id: 7,
      product: "Umm Al Quwain",
      percent: (85 / 500) * 100,
      color: 'hsla(33, 96%, 44%, 1)'
    },

  ])
  topSellingRegion.sort((a, b) => {
    if (a.percent < b.percent) return 1;
    if (a.percent > b.percent) return -1;
    return 0;
  })
  return (
    <div>
      <Text strong style={{ fontSize: '18px', lineHeight: '28px', color: '#101828' }}>Top Selling Region</Text>

      <Row gutter={[32, 0]}>
        {/* <Divider /> */}
        {/* <br/> */}
        <Col xs={24} lg={16}>

          <ComposableMap  >
            {/* <PatternLines
    //    id='lines'
       height={100}
       width={100}
       stroke="black"
       strokeWidth={1}
       orientation={['vertical', 'horizontal']}
      > */}
            <Geographies geography={geoUrl}>
              {({ geographies }) =>
                geographies.map((geo) => (
                  <Geography key={geo.rsmKey} geography={geo} style={{
                    default: {
                      fill: 'hsla(216, 16%, 84%, 1)',
                      outline: 'none',
                    },
                    hover: {
                      fill: 'none',
                      outline: "none"
                    },
                  }} />
                ))
              }
            </Geographies>
            {markers.map(({ name, coordinates, markerOffset }) => (
              <Tooltip title={<div style={{}}>
                <div>{name}</div>
                <div>{coordinates[0]} %</div>
              </div>
              } color={colorr} >
                <Marker key={name} coordinates={coordinates}>
                  <circle r={6} fill="hsla(33, 96%, 44%, 1)" stroke="#ee9f27" strokeWidth={15} strokeOpacity={0.4} />
                </Marker>
              </Tooltip>
            ))}
            {/* </PatternLines> */}
          </ComposableMap>

        </Col>
        <Col xs={24} lg={8} style={{ marginTop: '10px' }}>
          {/* <Card > */}
          <Text strong style={{ fontSize: '26.3519px' }}>
            10.8k
          </Text>
          <TopSellingRegion />
          {/* {topSellingRegion.map((topSellingProduct) => {
            console.log("%c percent value", "background: purple; color: white", topSellingProduct.percent)
            return (
              <Row style={{ marginTop: '6%' }}>
                <Col style={{}} span={24}>
                  <Text >{topSellingProduct.product}  </Text>
                </Col>
                <Col xs={24} md={20} style={{}}>
                  <Progress strokeColor={topSellingProduct.color} percent={topSellingProduct.percent} showInfo={true} strokeWidth={10} />
                </Col>
                <Col style={{  }} span={2}>
                            <p>{topSellingProduct.percent}%</p>
                        </Col>
              </Row>

            )
          })} */}
        </Col>
      </Row>
    </div>
  )
}

export default VectorMap
