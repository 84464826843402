import { Badge, Col, Row, Typography } from 'antd'
import React, { useState } from 'react'
import { AiOutlineArrowUp, AiOutlineArrowDown } from 'react-icons/ai';

const {Text} = Typography;
const AccountDetails = () => {
  const [accountDetails, setAccountDetails] = useState([
    {
      id: 1,
      title:'Total Earning',
      price: '53,45',
      percent: '7.4%',
      icon: <AiOutlineArrowUp />
    },
    {
      id: 2,
      title:'Current Balance',
      price: '3,245',
      icon: <AiOutlineArrowUp />,
      percent: '7.4%'
    },
    {
      id: 3,
      title:'Withdrawal',
      price: '1,245',
      icon: <AiOutlineArrowUp />,
      percent: '7.4%'
    },
  ])
  return (
    <div style={{margin:'10px 0'}}>
      <Text style={{color:'hsla(221, 43%, 11%, 1)', fontSize:'20px', fontWeight:'600'}}>Account Details</Text>
    {accountDetails.map((accountDetails)=> {
        return (
          <Row gutter={[0,0]} 
          // style={{ marginTop:'13px'}}
          >
         <Col span={24} style={{marginTop:'10%', color:'hsla(215, 18%, 34%, 1)'}}>{accountDetails.title}</Col>
          <Col span={24}>
       
          <Row gutter={[32,32]}>
            <Col xs={19} 
            // sm={14}
            sm={19}
             md={16} style={{color:'hsla(221, 43%, 11%, 1)', fontSize:'22px', fontWeight:'600'}}>{accountDetails.price} </Col>
            <Col xs={4} 
            // sm={9}
            sm={4}
             md={7} style={{ width: '100%', height: '30px', padding: '9px', borderRadius: '30px', backgroundColor: '#ECFDF3', display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}> 
            {/* <span style={accountDetails.percent < 0 ? { width: '65px', height: '30px', padding: '9px', borderRadius: '30px', backgroundColor: '#ECFDF3', display: 'flex', justifyContent: 'space-between', alignItems: 'center', } : { width: '65px', height: '30px', padding: '9px', borderRadius: '30px', backgroundColor: '#ECFDF3', display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}> */}
                            <span style={accountDetails.percent < 0 ? { color: 'hsla(152, 82%, 39%, 1)',textAlign:'start', height: '17px', border: 'border: 1.6px solid hsla(152, 82%, 39%, 1)', } : { color: 'hsla(152, 82%, 39%, 1)', height: '17px', textAlign:'start', border: 'border: 1.6px solid hsla(152, 82%, 39%, 1)' }}>{accountDetails.icon} </span>
                            <span style={accountDetails.percent < 0 ? { color: '#027A48', fontWeight: 'bold', } : { color: '#027A48', fontWeight: 'bold', }}>{accountDetails.percent} </span>
                          {/* </span> */}
                          </Col>
 
          </Row>
          </Col>
          </Row>
          )
      })}
     </div>
  )
}

export default AccountDetails
