import React, { useEffect } from "react";

import { Card, Row, Col, Divider, Button } from "antd";
import { useSelector } from "react-redux";
import { toTitleCase } from "../../../../../configs/helper";

export default function AllOrders() {
  const useProductRedux = useSelector((state) => state.orders.dashboardCount);

  useEffect(() => {
   
  }, []);

  const statuses = [
    { _id: "processing" },
    { _id: "ready-ship" },
    { _id: "completed" },
    { _id: "pendingPayment" },
    { _id: "cancelled" },
    { _id: "refunded" },
  ];

  const formatted = {
    processing: "Processing",
    "ready-ship": "Ready Ship",
    completed: "Completed",
    pendingPayment: "Pending Payment",
    cancelled: "Cancelled",
    refunded: "Refunded",
  };
  return (
    <Card
      style={{ minHeight: "418px" }}
      className="hp-border-color-black-40 hp-card-3 hp-chart-text-color"
    >
      <Row>
        <Col className="hp-mb-8" span={24}>
          <Row justify="space-between">
            <Row align="bottom">
              <h4 className="hp-mr-8">Orders</h4>
            </Row>
          </Row>
        </Col>

        <Col span={24}>
          <Button type="link" className="hp-p-0" href="/orders">
            See all orders
          </Button>
          <Divider className="hp-my-12" />
           {statuses.map((item) => (
            <>
              <Row>
                <Col md={12} span={12}>
                  {useProductRedux !== undefined && useProductRedux?.orderStatus.find(
                    (item1) => item1._id == item._id
                  )
                    ? formatted[
                        useProductRedux.orderStatus.find(
                          (item1) => item1._id == item._id
                        )._id
                      ]
                    : formatted[item._id]}
                </Col>
                <Col md={12} span={12} className="hp-text-right">
                  {useProductRedux !== undefined && useProductRedux?.orderStatus.find(
                    (item1) => item1._id == item._id
                  )
                    ? useProductRedux.orderStatus.find(
                        (item1) => item1._id == item._id
                      ).count
                    : 0}
                </Col>
              </Row>
              <Divider className="hp-my-12" />
            </>
          ))}
        </Col>
      </Row>
    </Card>
  );
}
