import { Link } from "react-router-dom";
import { Dropdown, Col, Avatar, Divider, Row } from "antd";
import { Setting } from "react-iconly";
// import { details } from "../../../configs/details";
import Cookies from "js-cookie";
import { allUsers, getAllSeller } from "../../../service/api";
import { useEffect, useState } from "react";

export default function HeaderUser() {
  const [currentSellerData, setCurrentSellerData] = useState({})
  const currentEmail = localStorage.getItem("mjSeller_email");
  const [userDetail, setUserDetail] = useState('');
  const [details, setDetails] = useState(localStorage.getItem("matjar_user_data") ? localStorage.getItem("matjar_user_data"): null)
useEffect(()=>{
  const detailData = details ? JSON.parse(details) : null
  setUserDetail(detailData);
},[details]);
  // ------------ Get All Seller Data
  const getAllSellerData = async () => {
    try {
      const getAllSellers = await getAllSeller({ page: 1, limit: 50 })
     let getAllUsers = await allUsers({ page: 1, limit: 50 });
      // To Get Data Only CurrentUSer By matching of current Email from all Users API
      let getCurrentUserData = getAllUsers.data.list.filter((getalluser) => getalluser.email === currentEmail)
      // To Get Data Only Current User By matching of Extracted Current USer from all Seller API
      let getCurrentSellerData = getAllSellers.data.list.filter((getAllSeller) => getCurrentUserData.length > 0 && getCurrentUserData[0].length != 0 && getAllSeller.userId === getCurrentUserData[0]._id)
      if (getCurrentSellerData.length > 0) {
        var { withdrawType, Withdraw, logo, _id, userId, documents, status, storeName, statusReason } = getCurrentSellerData[0];
        var { firstName, lastName, email, phone, dob, gender, profilePic, type } = getCurrentUserData[0]
        let currentSellerDataa = {
          "withdrawType": withdrawType,
          "Withdraw": Withdraw,
          "logo": logo,
          "documents": documents,
          "status": status,
          "storeName": storeName,
          "statusReason": statusReason,
          "userData": {
            "firstName": firstName,
            "lastName": lastName,
            "email": email,
            "phone": phone,
            "dob": dob,
            "gender": "",
            "profilePic": profilePic,
            "type": type
          },
          "selectedPersonalDoc": "Emirates ID",
          "selectedBusinessDoc": "Trade License",
        }
       setCurrentSellerData(currentSellerDataa);
        localStorage.setItem("currentUserData", JSON.stringify(currentSellerDataa))
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getAllSellerData()
  }, [])

  const menu = (
    <div
      className="hp-border-radius hp-border-1 hp-border-color-black-40 hp-bg-black-0 hp-bg-dark-100 hp-border-color-dark-80 hp-p-24 hp-mt-12"
      style={{ width: 260 }}
    >
      <span className="hp-d-block h5 hp-text-color-black-100 hp-text-color-dark-0 hp-mb-8">
        Profile Settings
      </span>

      <Link
        to="/account-and-settings"
        className="hp-p1-body hp-text-color-primary-1 hp-text-color-dark-primary-2 hp-hover-text-color-primary-2"
      >
        View Profile
      </Link>

      <Divider className="hp-mt-16 hp-mb-6" />

      <Row>
        <Col span={24}>
          <Link
            to="/"
            className="hp-d-flex-center hp-p1-body hp-py-8 hp-px-10 hp-d-block hp-transition hp-hover-bg-primary-4 hp-hover-bg-dark-80 hp-border-radius"
            style={{ marginLeft: -10, marginRight: -10 }}
          >
            <Setting set="curved" size={16} />

            <span className="hp-ml-8">Setting</span>
          </Link>
        </Col>
      </Row>

      <Divider className="hp-mb-16 hp-mt-6" />

      <Link
        to="/authentication/login"
        className="hp-p1-body"
        onClick={() => {
          localStorage.clear();
          Cookies.remove("session");
          Cookies.remove("Token");
          Cookies.remove("email");
        }}
      >
        Logout
      </Link>
    </div>
  );

  return (
    <Col>
      <Dropdown overlay={menu} placement="bottomLeft">
        <Avatar
          src={userDetail && userDetail?.profilePic}
          size={40}
          className="hp-cursor-pointer"
        />
      </Dropdown>
    </Col>
  );
}
