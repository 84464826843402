import React from "react";
import { useDispatch } from "react-redux";
import { showSessionModal } from "./redux/auth/authActions";

function useApp() {
  const dispatch = useDispatch();
  const sessionExpired = () => {
    dispatch(showSessionModal());
  };

  return {
    sessionExpired,
  };
}

export default useApp;
