
import { Button, Modal } from 'antd';
import React, { useState } from 'react';
import BuyerLogin from './buyerLogin';
import BuyerRegister from './buyerRegister';
const BuyerModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <Button type="primary" onClick={showModal}>
        Switch to buyer
      </Button>
      <Modal title="Basic Modal" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <p>want to stay with same email 
            {/* <a href='/buyer-login'> */}
           {/* <p onClick={handleOk}> */}
             <BuyerLogin />
              {/* </p> */}
            {/* </a> */}
            </p>
        <p>or another email  <a href='/buyer-register'> Register</a></p>
      </Modal>
    </>
  );
};
export default BuyerModal;