export const ADD_PRODUCTS = "ADD_PRODUCTS";
export const ADD_SEARCH = "ADD_SEARCH";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const ADD_BRAND = "ADD_BRAND";
export const USE_PRODUCT = "USE_PRODUCT";
export const PRODUCT_FORM = "USEPRODUCT_FORM_PRODUCT";
export const IS_PRODUCT_CREATE = "IS_PRODUCT_CREATE";
export const ADD_ATTRIBUTE = "ADD_ATTRIBUTE";
export const PRODUCT_INCREMENT = "PRODUCT_INCREMENT";
export const PRODUCT_DECREMENT = "PRODUCT_DECREMENT";
export const RESET_PRODUCT_COUNTER = "RESET_PRODUCT_COUNTER";
export const OPEN_TICKET_MODAL = "OPEN_TICKET_MODAL";
export const CLOSE_TICKET_MODAL = "CLOSE_TICKET_MODAL";
export const UPDATE_TICKET = "UPDATE_TICKET";
export const EDIT_PRODUCT = "EDIT_PRODUCT";
export const FILTER_PRODUCT = "FILTER_PRODUCT"
export const PRODUCTLIST = "PRODUCTLIST"
export const CATEGORY_FILTER = "CATEGORY_FILTER"
export const FILTER_TABLE_PRODUCT = "FILTER_TABLE_PRODUCT";
export const DATE_FILTER = 'DATE_FILTER';
// export const SHOW_PRODUCT = "SHOW_PRODUCT"

